import styled from "styled-components";
const CSATReportStyles = styled.div`
.container {
    padding: 40px;
    overflow-y: auto;
    height: 85vh;
    padding-top: 1.5rem;
}
  .top-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex-grow: 1;
    justify-content: space-between;
  }
  .horizontal-cards  {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: calc(112.5px - 1rem);
  }
  .horizontal-cards > div {
    background-color: white;
  }
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
  }
  .date-bo {
    margin-top:10px;
  } 
  .date-container {
    /* margin-left: 10rem; */
    flex :1;
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap : 1rem
  }
  .input-box {
    flex : 1
  }
  .table-container {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 7px 0px;
    margin-top: 3rem !important;
    padding: 0 !important;
  }
  .paper-card {
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    /* margin-top: 20px; */
    /* width: -webkit-fill-available; */
  }
  .display-button{
    margin-top: 1rem;
  }
 
`;
export default CSATReportStyles;
