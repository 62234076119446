import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const MAX_LENGTH = 14; // Set a max length to truncate the text

function CustomerReason({ customerReasonAI }) {
    const isLongText = customerReasonAI.length > MAX_LENGTH;
    const displayText = isLongText
        ? `${customerReasonAI.substring(0, MAX_LENGTH)}...`
        : customerReasonAI;

    return (
        <Tooltip title={customerReasonAI} placement="top">
            <span className="customer-reason">{displayText}</span>
        </Tooltip>
    );
}

export default CustomerReason;