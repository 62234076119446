import React from 'react';
import styled from 'styled-components';

const emojis = [
  { emoji: "😖", label: "Worst", rating: 1 },
  { emoji: "😟", label: "Not Good", rating: 2 },
  { emoji: "😐", label: "Fair", rating: 3 },
  { emoji: "😊", label: "Good", rating: 4 },
  { emoji: "😍", label: "Awesome", rating: 5 },
];

const Ratings = ({ rating }) => {
  return (
    <RatingsContainer>
      {/* <Label>{emojis[rating - 1].label}</Label> */}
      <EmojiContainer>
        {emojis.map(({ emoji, label, rating: emojiRating }) => (
          <EmojiItem key={emojiRating}>
            <Emoji 
              title={label}
              isSelected={rating === emojiRating}
            >
              {emoji}
            </Emoji>
          </EmojiItem>
        ))}
      </EmojiContainer>
    </RatingsContainer>
  );
};

const RatingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`;

const EmojiContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const EmojiItem = styled.div`
  display: flex;
  align-items: center;
`;

const Emoji = styled.span`
  font-size: .75rem;
  filter: ${props => props.isSelected ? 'grayscale(0)' : 'grayscale(1)'};
  transition: filter 0.3s ease;
  
  @media (min-width: 640px) {
    font-size: 1rem;
  }
`;

const Label = styled.p`
  font-size: 0.875rem;
  color: #333;
  font-weight: 500;
  margin: 0;
`;

export default Ratings;