import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const CSATSkeletonLoadingDashboard = () => {
  return (
    <Box >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4.5}>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: 2,
              backgroundColor: "white",
              height: "100%",
            }}
          >
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width={50} height={40}  sx={{ mt: 2 }}/>
            <Skeleton variant="text" width="100%" height={40} sx={{ mt: 2 }}/>
            <Skeleton variant="text" width="100%" height={40} sx={{ mt: 2 }}/>
          </Box>
        </Grid>
        <Grid item xs={12} md={7.5}>
          <Grid container spacing={2}>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                    padding: 2,
                    backgroundColor: "white",
                  }}
                >
                  <Skeleton variant="text" width="60%" />
                  <Skeleton
                    variant="text"
                    width={50}
                    height={40}
                    sx={{ mt: 2 }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: 1,
          padding: 2,
          backgroundColor: "white",
        }}
      >
        <Skeleton variant="rectangular" height={300} />
      </Box>
    </Box>
  );
};

export default CSATSkeletonLoadingDashboard;
