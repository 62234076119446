import React from "react";
import CustomAvatar from "../reusable/CustomAvatar";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ProductMessage from "./ProductMessage";
import MessageBoxStyles from "../../assets/styledComponents/chatBox/MessageBox";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import MessageImage from "./MessageImage";
import MessageAttachment from "./MessageAttachment";
import ContactUsMessage from "./ContactUsMessage";
import HighlightWords from "./HighlightWords";
import AutoFixNormalOutlinedIcon from '@mui/icons-material/AutoFixNormalOutlined';
import SocialMessage from "./SocialMessage";
import Tooltip from "@mui/material/Tooltip";
import Merge from "@mui/icons-material/Merge";
import ChannelIcon from "../inboxMessage/ChannelIcon";
import RateYourExperience from "../message/RateYourExperience";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ProductCarousel from "./ProductCarousel";

const MessageBox = React.forwardRef((props, ref) => {

  const { currentSessionData, setSelectedComment,  } = useInboxContext();

  const { assignTo, storeName, tenantId, channelName, isMerged , mergedSessionId, mergedTicketNumber, mergedChannelName } = currentSessionData || {};

  const {
    children,
    sender,
    name,
    time,
    isSeen,
    isPrivate,
    isBot,
    userId,
    productDetails,
    image,
    height,
    width,
    _id,
    attachment,
    ticketNote,
    showSeenStatus,
    contactUs,
    taggedUserName,
    attachmentSize,
    facebookCommentMetaData,
    instagramCommentMetaData,
    isCurrentUser,
    postId,
    chatSession,
    isReview,
    isAiBot,
    aiProducts,
  } = props;
  // const { postId, commentId, isLiked } = facebookCommentMetaData || {};

  const handleReplyClick = (data) => {
    if (data?.type === "nestedReply") {
      const { postId, commentId, content } = data || {};
      if (!assignTo) return;
      setSelectedComment({
        postId,
        commentId,
        content,
      });
      return;
    }
    console.log("Inside Handle Reply", facebookCommentMetaData, instagramCommentMetaData);

    const { commentId } = facebookCommentMetaData || instagramCommentMetaData || {};
    console.log("Comment ID", commentId);
    if (!assignTo) return;
    setSelectedComment({
      postId,
      commentId: commentId,
      content: children
    });
  }
  return (
    <MessageBoxStyles>
      <div className={`msg-container`} ref={ref && ref}>
        <div
          className={`${sender ? "sender-container" : "receiver-container"}`}
        >
          {!sender && (
            <div className="avatar-container">
              <CustomAvatar name={name} />
            </div>
          )}
          {facebookCommentMetaData || instagramCommentMetaData ? (
            <SocialMessage
              userId={userId}
              facebookCommentMetaData={facebookCommentMetaData}
              instagramCommentMetaData={instagramCommentMetaData}
              msg={children}
              shop={storeName}
              tenantId={tenantId}
              time={time}
              handleReplyClick={handleReplyClick}
              isCurrentUser={assignTo === userId}
              messageId={_id}
              channelName={channelName}
            />
          ) : (
            <div className="msg-container">
              {children && !attachment && (
                <div
                  className={`${sender ? "sender-msg" : "receiver-msg"} ${
                    isPrivate ? "private-msg private-receiver-msg" : ""
                  } `}
                >
                  {contactUs && <ContactUsMessage data={contactUs} />}
                  {isPrivate && (
                    <HighlightWords
                      text={children}
                      phrasesToHighlight={taggedUserName}
                    />
                  )}

                  {isReview ? (
                    <RateYourExperience {...currentSessionData.review} />
                  ) : !isPrivate && children ? (
                    children
                  ) : null}
                </div>
              )}

              {children && attachment ? (
                <div className={`sender-msg ${image ? "image-space" : ""}`}>
                  <div className="attachment-container">
                    <MessageAttachment url={attachment} />
                  </div>
                  <div className="attachment-msg">{children}</div>
                </div>
              ) : attachment ? (
                <div className={`sender-msg ${image ? "image-space" : ""}`}>
                  <div className="attachment-container">
                    <MessageAttachment
                      channelName={channelName}
                      url={attachment}
                      size={attachmentSize}
                    />
                  </div>
                </div>
              ) : null}

              {productDetails && Object.keys(productDetails).length > 1 && (
                <div className="sender-msg">
                  <ProductMessage
                    productDetails={productDetails}
                    message={children}
                  />
                </div>
              )}

              {aiProducts?.length > 0 && (
                <ProductCarousel products={aiProducts} />
              )}
              {console.log({ _id, image })}
              {image && (
                <MessageImage
                  imageSrc={image}
                  id={_id}
                  width={width}
                  height={height}
                />
              )}
              <div className={`${sender ? "sender-time" : "receiver-time"}`}>
                {/* <p className="time">{time}</p> */}
                {/* {facebookCommentMetaData && !sender && (
                  <Like
                    isLiked={isLiked}
                    commentId={commentId}
                    messageId={_id}
                  />
                )} */}

                {isPrivate && isCurrentUser && (
                  <p className="tagged-user-name">
                    <b>Private note by : </b>
                    {userId?.name}
                  </p>
                )}
                <p
                  className={`${
                    isPrivate && isCurrentUser ? "private-time-stamp" : "time"
                  } ${sender ? "sender-timestamp" : "receiver-timestamp"}`}
                >
                  <div className="time-container">
                    {isBot && (
                      <div className="bot-icon">
                        <Tooltip title="Message by Bot" placement="bottom">
                          <AutoFixNormalOutlinedIcon />
                        </Tooltip>
                      </div>
                    )}
                    {time}
                  </div>
                  {isMerged && chatSession !== currentSessionData._id&& (
                    <div className="merge-icons">
                      <Tooltip
                        title={`This message belong to Ticket number ${props.ticketId}`}
                        placement="top-start"
                      >
                        <Merge sx={{ color: "#8db178" }} />
                      </Tooltip>
                      <ChannelIcon channelName={mergedChannelName} />
                    </div>
                  )}
                </p>
              </div>
            </div>
          )}
          {isAiBot && sender ? (
            <SmartToyIcon
              style={{ alignSelf: "center", marginBottom: "1rem" }}
            />
          ) : (
            showSeenStatus &&
            ticketNote && (
              <div
                className={`${isPrivate ? "seen-icon-private" : "seen-icon"}`}
              >
                {isSeen ? (
                  <CheckCircleOutlineRoundedIcon
                    sx={
                      isPrivate
                        ? { color: "#ffc453" }
                        : { color: "var(--primary-500)" }
                    }
                  />
                ) : (
                  <CircleOutlinedIcon
                    sx={
                      isPrivate
                        ? { color: "#ffc453" }
                        : { color: "var(--primary-500)" }
                    }
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>
    </MessageBoxStyles>
  );
});
MessageBox.displayName = "MessageBox";

export default MessageBox;
