import React, { useEffect, useState } from "react";
import CustomTextField from "../../../components/reusable/CustomTextField";
import CustomSwitch from "../../../components/CustomSwitch";
import ContactFormStyles from "../../../assets/styledComponents/channelConfiguration/chat/ContactForm";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
import CustomSelect from "../../../components/reusable/CustomSelect";

const OrderStatus = () => {
  const {
    widgetFormattedList,
    widgetFormattedListError,
    selectedWidgetShop,
    setWidgetFormattedList,
    setError,
    setCurrentTabValue,
    setOpenTab,
    handleTrackOrderClick,
  } = useChannelContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  const { orderStatus } = selectedWidgetData;

  const selectedWidgetDataError = widgetFormattedListError[selectedWidgetShop];
  const { orderStatus : orderStatusError } = selectedWidgetDataError;
  const {title : tittleError , message : messageError} = orderStatusError  || {}
  

  const setOrderStatus = (key, value) => {
    setWidgetFormattedList({
      ...widgetFormattedList,
      [selectedWidgetShop]: {
        ...widgetFormattedList[selectedWidgetShop],
        orderStatus: {
          ...widgetFormattedList[selectedWidgetShop].orderStatus,
          [key]: value,
        },
      },
    });
  };

  // useEffect(() => {
  //   if (!orderStatus.orderSearch) {
  //     setCurrentTabValue("Appearance");
  //   }
  // }, []);
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      // if (name === "orderSearch") {
      //   if (!checked) {
      //     setCurrentTabValue("Appearance");
      //   } else {
      //     setCurrentTabValue("Track Order");
      //   }
      // }
      setOrderStatus(name, checked);
      return;
    }
    setOrderStatus(name, value);
    const currentShopOrderStatus = widgetFormattedListError[selectedWidgetShop].orderStatus;
    console.log({currentShopOrderStatus })
    if (currentShopOrderStatus[name]) {
      setError("orderStatus", name, "")
      return
    }
    return
  };

  const handleDropDownChange = (e) => {
    const { name, value } = e.target;
    console.log({name, value})
    setOrderStatus(name, value);
    const currentShopOrderStatus = widgetFormattedListError[selectedWidgetShop].orderStatus;
    if (currentShopOrderStatus[name]) {
      setError("orderStatus", name, "")
      return
    }
    return
  }
  const { title, message, titlePlaceholder, messagePlaceholder  } =
    orderStatus;
  return (
    <ContactFormStyles>
      <div className="text-field-wrapper">
        <h3 className="theme-heading4-500">Title</h3>
        <CustomTextField
          value={title}
          placeholder={titlePlaceholder}
          name={"title"}
          handleChange={handleChange}
          helperText={tittleError}
          error={!!tittleError}
        />
      </div>
      <div className="text-field-wrapper">
        <h3 className="theme-heading4-500">Brief Message</h3>
        <CustomTextField
          value={message}
          placeholder={messagePlaceholder}
          name={"message"}
          handleChange={handleChange}
          multiline
          rows={3}
          helperText={messageError}
          error={!!messageError}
        />
      </div>
    </ContactFormStyles>
  );
};

export default OrderStatus;
