import styled from "styled-components";
const StoreStyles = styled.div`
  .empty-order-state {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 20px;
    text-align: -webkit-center;
  }
  .search-container {
    width: 90%;
  }
  .main-store-container {
    width: 300px;
    overflow: hidden;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100vh;
  }

  .tab-pannel-wrapper {
    padding: 10px 10px;
  }
  .select-store .name {
    font-size: 16px;
    font-weight: 400;
    margin-left: .25rem;
  }

  .order-info-wrapper {
    height: 80vh;
    overflow-y: auto;
    width: inherit;
    padding-bottom: 3rem;
  }
  .select-store .dropdown {
    /* padding: 1rem 0; */
    padding-bottom: 0.5rem;
  }
  .status-chip {
    width: 100px;
    display: flex;
    justify-content: space-between;
  }
  .light-gray {
    color: #969696;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
  }
  .dark-black {
    color: #292524;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
  }
  .heading {
    font-size: 12px;
    font-weight: 500;
  }
  .product-heading {
    align-self: flex-start;
  }
  .secondary-heading {
    color: #808080;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    /* margin-left: -0.5rem; */
  }
  .underline {
    height: 1px;
    background-color: #808080;
    /* margin: 0 auto; */
    /* margin-left: -0.5rem; */
    /* margin-right: 1rem; */
  }
  .select-store {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 0.5rem;
  }
  .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #808080; */
    margin-top: 0.5rem;
  }

  .details-wrapper {
    margin-top: 1rem;
  }
  .data-list {
    padding: 0.6rem 0rem;
  }
  .data-list .row {
    /* margin-left: -0.5rem; */
    display: flex;
    margin-bottom: 0.5rem;
  }
  .value {
    max-width: 150px;
    color: #292524;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
  }
  .key {
    width: 100px;
    color: #808080;
    font-size: 11px;
    font-weight: 400;
  }

  /* order info */

  .order-info-container {
    margin-top: 0.5rem;
  }
  .order-info-container,
  .heading {
  }

  .order-id,
  .id {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 1rem;
    /* margin-left: -0.25rem; */
  }

  .order-id p {
  }

  .order-card {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    gap: 0.2rem;
  }

  .order-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 0.5rem; */
  }
  .order-details p {
    font-size: 0.75rem;
  }
  .details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
  }
  .price {
    font-size: 12px;
    font-weight: 500;
    display: block;
  }
  .product-container {
  }
  .order-spec {
    display: flex;
    gap: 1rem;
  }
  .size,
  .qty {
    font-size: 0.75rem;
    color: var(--gray-700);
  }
  .unit {
    color: black;
  }
  .status {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: var(--primary-200);
    color: var(--primary-900);
  }
  .details-container {
    /* margin-top: 0.5rem; */
  }
  .product-info {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    justify-content: flex-end;
  }
  .marked-customer {
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }

  .product-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .product-id .name {
    display: flex;
    gap: 0.5rem;
  }
  .create-order-btn {
    color: var(--primary-500);
    cursor: pointer;
    text-decoration: underline;
  }

  .create-order-btn-container {
    font-size: 11px;
    border: 1px solid;
    border-color: var(--primary-700);
    padding: 8px;
    border-radius: 6px;
  }

  .create-order-btn-container a {
    text-decoration: none !important;
  }

  .create-order-btn-container  .create-order-btn {
    text-decoration: none !important;
  }

  .product-list-container {
    overflow-y: auto;
    height: 90vh;
    padding-bottom: 8.5rem;
  }
  .error-message {
    text-align: center;
    margin-top: 90%;
  }
  .img-container {
    box-shadow: 0px -1px 10px -1px rgba(0, 0, 0, 0.75);
    margin-right: 0.5rem;
    align-self: center;
    /* margin: 0.5rem 0; */
  }
  @media (max-width: 1200px) {
    padding-inline: .25rem;
    .main-store-container {
    width: 200px;
    padding-inline: 0;
  }
} 

`;
export default StoreStyles;
