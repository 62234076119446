import React, { useEffect, useContext, useRef } from "react";
import FullPageSpinner from "../components/FullPageSpinner";
import { useParams, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { buyAdditionalTickets, verifyAiToken, verifySubscriptionPlan, verifyTicketPurchase } from "../service/service";
import { TenantContext } from "../context/TenantContext";

const VerifySubscription = () => {
  const { profile, tenantId} = useContext(TenantContext);
  const { tenantId : tenantKey , pricingType} = useParams();
  const navigate = useNavigate()
  const { setToken } = useContext(TenantContext);

  const checkPricingStatus = async (chargeId, shop) => {
    const res = await verifySubscriptionPlan({
     chargeId: chargeId,
      tenantKey,
      shop,
      pricingType,
     })

    if (res.success && res.data.isValid) {
      if (res.data.data){
        setToken(res.data.data);
      }
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1000);
    }
  };

  const buyMoreTicketsStatus = async (chargeId) => {
    try {
      const pricingPlan = profile?.token?.pricingPlan;
      const buyTicketsRes = await verifyTicketPurchase({
        chargeId : chargeId, tenantKey , pricingType
      });
      window.location.href = window.location.origin;
    } catch (error) {
      console.log(error);
    }
  };

  const buyMoreAiTokenStatus = async (chargeId) => {
    console.log('inside buyMoreAiTokenStatus ')
    try {
      const pricingPlan = profile?.token?.pricingPlan;
      const buyTicketsRes = await verifyAiToken({
        chargeId , tenantKey , pricingType
      });
      window.location.href = window.location.origin;
    } catch (error) {
      console.log(error);
    }
  };


  const initialRender = useRef(true);
  useEffect(() => {

    {console.log("Inditial Render ---", initialRender.current)}
    if (initialRender.current) {
      const parsedQs = queryString.parse(location.search);
      console.log(parsedQs, 'parsedQs' , pricingType ===  'additionalTicket' && tenantKey && parsedQs?.charge_id && parsedQs?.shop)

      if (pricingType ===  'additionalTicket' && tenantKey && parsedQs?.charge_id && parsedQs?.shop) {
        buyMoreTicketsStatus(parsedQs?.charge_id);
        initialRender.current = false;
        return 
      }

      if (pricingType ===  'additionalAiTokens' && tenantKey && parsedQs?.charge_id && parsedQs?.shop) {
        buyMoreAiTokenStatus(parsedQs?.charge_id);
        initialRender.current = false;
        return 
      }

      
      if (parsedQs?.charge_id && tenantKey && parsedQs?.shop) {
        checkPricingStatus(parsedQs.charge_id, parsedQs?.shop);
        initialRender.current = false;
        return
      }
      if (parsedQs.charge_id && tenantKey ) {
        checkPricingStatus(parsedQs.charge_id);
        initialRender.current = false;
      } else {
        window.location = "/404";
      }
    }
  }, []);

  return <FullPageSpinner />;
};

export default VerifySubscription;
