import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

const ModalStyles = styled.div`
  /* width: 300px; */
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: var(--border);
  }
  .heading-container {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .template-container {
    padding: 0 1px;
  }
`;

function Modal({
  onClose,
  selectedValue,
  open,
  name,
  children,
  navChildren,
  showNavBar = true,
  disableEscapeClose = false,
  disableBackdropClose = false,
  icon,
}) {
  const handleClose = (event, reason) => {
    if (disableBackdropClose && reason === 'backdropClick') {
      return;
    }

    if (disableEscapeClose && reason === 'escapeKeyDown') {
      return;
    }

    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <ModalStyles>
        <div className="container">
          {showNavBar && (
            <div className="top">
              <div className="heading-container">
                <h4>{name}</h4>
                {navChildren}
              </div>
              <div>
                {icon}
              <button onClick={handleClose}>
                <CloseIcon />
              </button>
                </div>
            </div>

          )}
          {children}
        </div>
      </ModalStyles>
    </Dialog>
  );
}

export default Modal;

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.element,
};
