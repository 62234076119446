import React, { useState, useEffect } from "react";
import ReportStyles from "../../../assets/styledComponents/Report";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import CustomDate from "../../reusable/ReportFeature.js/CustomDate";
import CustomReportFilter from "../CustomReportFilter";
import CustomReportTable from "../CustomReportTabel";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { getAllChannelReports } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import FullPageSpinner from "../../FullPageSpinner";
import { Tab, Tooltip } from "@mui/material";
import Table from "../../reusable/table/Table";

const ChannelReport = () => {
  const [channelTableDetails, setChannelTableDetails] = useState([]);
  const [orginalChannelTableData, setOriginalChannelTableData] = useState([]);

  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [channelFilterValue, setChannelFilterValue] = useState("All");
  const { tenantId } = useTenantContext();

  const CHANNEL_LIST = [
    { name: "All" },
    { name: "Chat" },
    { name: "Instagram" },
    { name: "Whatsapp" },
    { name: "Facebook" },
    { name: "Email" },
  ];

  const NewHeader = (
  <Tooltip title="New Tickets"  placement="top-start"  >
    <span style={{ cursor: 'pointer'}}>
    New 
    </span>
  </Tooltip>)

const ClosedHeader = (
  <Tooltip title="Closed Tickets" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      Closed
    </span>
  </Tooltip>
);

const AFRTHeader = (
  <Tooltip title="Average First Response Time (mins)" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      AFRT
    </span>
  </Tooltip>
);

const ARTHeader = (
  <Tooltip title="Average Revolution Time (mins)" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      ART
    </span>
  </Tooltip>
);

const MsgRecieveHeader = (
  <Tooltip title="Message Recieve" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      Msg Recieve
    </span>
  </Tooltip>
);

const MsgSentHeader = (
  <Tooltip title="Message Sent" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      Msg Sent
    </span>
  </Tooltip>
);

const IntervalMsgHeader = (
  <Tooltip title="Interval Message" placement="top-start">
    <span style={{ cursor: 'pointer' }}>
      Interval Msg
    </span>
  </Tooltip>
);


  
  
  const columns = React.useMemo(
    () => [
      { headerName: "Channels", field: "channel" },
      { headerName: NewHeader, field: "newConversations" },
      { headerName: ClosedHeader, field: "closedConversations" },
      {
        headerName: AFRTHeader,
        field: "averageFirstResponse",
      },
      { headerName: ARTHeader, field: "averageResolutionTime" },
      { headerName: MsgRecieveHeader, field: "messageRecieved" },
      { headerName: MsgSentHeader, field: "messageSent" },
      { headerName: IntervalMsgHeader, field: "internalMessage" },
    ],
    []
  );

  const fetchChannelTableSetup = async (customStartDate, customEndDate, shop) => {
    setTableLoading(true);
    try {
      const result = await getAllChannelReports(
        tenantId,
        customStartDate,
        customEndDate,
        shop,
      );

      console.log(". Channel Report Result.", result.data);

      const newRows = await Promise.all(result?.data?.map((rowData, index) => {
        return {
            id: index + 1,
            ...rowData
        }
      }))
      setChannelTableDetails(newRows);
      setOriginalChannelTableData(newRows);
      setShowTable(true);
    } catch (error) {
      console.error("Error fetching channel reports:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setChannelFilterValue(selectedValue);

    setTableLoading(true);
    if (selectedValue === "All") {
      setChannelTableDetails(orginalChannelTableData);
    } else {
      const newTableData = orginalChannelTableData.filter(
        (channelRowData) => channelRowData.channel === selectedValue
      );
      setChannelTableDetails(newTableData);
    }

    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleClick = async (customStartDate, customEndDate,shop) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      await fetchChannelTableSetup(customStartDate, customEndDate,shop);

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  return (
    <ReportStyles>
      <HeaderBar
        title="Channel Report"
        notification="Data last updated 5min ago"
        hidePrimaryButton={true}
        hideSecondaryButton={true}
        showDescription={true}
        paperHeader={true}
        description="Gain Insight how much traffic on your channels get, how customer prefer more contact you "
      />
      <div className="report-container">
      <CustomDate isLoading={isLoading} handleClick={handleClick} />

      {showTable && (
        <CustomReportFilter
          heading="Channel"
          label="Channels"
          marginRight="1px"
          selectList={CHANNEL_LIST}
          icon={<PeopleAltIcon />}
          handleChange={handleChange}
          value={channelFilterValue}
        />
      )}
      {showTable &&
        (isLoading ? (
          <FullPageSpinner />
        ) : (
          // <div className="report-table paper-card">
          //   {" "}
          //   <CustomReportTable
          //     columns={columns}
          //     data={channelTableDetails}
          //     tableLoading={tableLoading}
          //   />
          // </div>
          <div className="report-table paper-card">
          {" "}
          <Table 
            headerData={columns}
            rowData={channelTableDetails}
            // currentPage={currentPage}
            // setPage={handlePageChange}
            loading={tableLoading}
            totalPages={1}
            tableLoading={tableLoading}
            emptyTableMessage="No data found"
            // setLimit={setLimit}
            // limit={limit}
            // totalDocs={totalDocs}
            showPagination={false}
          />
        </div>
        
        ))}
      </div>
    </ReportStyles>
  );
};

export default ChannelReport;
