import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TablePagination, Typography } from "@mui/material";

export default function Table({
  headerData,
  rowData,
  limit,
  setLimit,
  totalDocs,
  currentPage,
  setPage,
  loading,
  showPagination = true
}) {
  const onLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(0);
  };

  console.log({ currentPage, totalDocs, limit });
  return (
    <div>
      {rowData.length > 0 ? (
        <>
          <DataGrid
            rows={rowData}
            loading={loading}
            columns={headerData.map((col) => ({
              ...col,
              sortable: false, // Disables sorting
              flex: 1,
            }))}
            disableColumnResize
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "Mui-even"
                : "Mui-odd"
            }
            disableColumnMenu
            // pagination={showPagination}
            getRowHeight={() => "30px"}
            // styles to remove default pagination
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
              },
              ".MuiDataGrid-container--top [role='row']" : {
                backgroundColor : 'black',
                color : 'white'
              },
              ".MuiDataGrid-row:nth-child(even)": {
                backgroundColor: "#f5f5f5",
              },
              ".MuiDataGrid-columnSeparator--sideRight": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-footerContainer": {
                display: "none",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "unset !important",
              },
            }}
          />
          {showPagination && <TablePagination
            rowsPerPageOptions={[1, 10, 25, 50]}
            component="div"
            count={totalDocs}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={(e, value) => setPage(value + 1)}
            onRowsPerPageChange={onLimitChange}
          />}
        </>
      ) : (
        <Typography variant="h6" align="center" sx={{ padding: 5 }}>
          No data available
        </Typography>
      )}
    </div>
  );
}
