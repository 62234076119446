import {
  BEGIN_GET_CHAT_MESSAGE,
  BEGIN_SET_CURRENT_CHAT,
  DELETE_MESSAGE,
  GET_CHAT_MESSAGE,
  GET_PRIVATE_MESSAGE,
  REMOVE_IMAGE,
  SEND_MESSAGE,
  SET_CURRENT_CHAT,
  SET_IMAGE,
  SET_MESSAGE_READ,
  SET_NORMAL_MESSAGE,
  SET_SINGLE_MESSAGE_READ,
} from "./inboxAction";

const inboxReducer = (state, action) => {
  if (action.type === BEGIN_SET_CURRENT_CHAT) {
    return {
      ...state,
      isMessageLoading: true,
    };
  }
  if (action.type === SET_CURRENT_CHAT) {
    const { allSessionMessage, numOfPages} = action.payload.data;
    const isReview = action.payload.isReview;
    return {
      ...state,
      normalMessage: isReview
        ? [
            {
              ...allSessionMessage[allSessionMessage.length - 1],
              content: "review",
              sender: "user",
              isReview: true,
              isPrivate : false,
              isAiBot : false,
            },
            ...allSessionMessage,
          ]
        : allSessionMessage,
      sessionId: action.payload.sessionId,
      isMessageLoading: false,
      numOfPages,
    };
  }
  if (action.type === SET_NORMAL_MESSAGE) {
    return {
      ...state,
      normalMessage: [],
    };
  }
  if (action.type === BEGIN_GET_CHAT_MESSAGE) {
    if (action.payload.page === 1) {
      return {
        ...state,
        isMessageLoading: true,
      };
    }else {
      return {
        ...state,
      }
    }
  }

  if (action.type === GET_CHAT_MESSAGE) {
    const { allSessionMessage, numOfPages } = action.payload.data;
    if (allSessionMessage?.length === 0)
      return {
        ...state,
        isMessageLoading: false,
      };
    const updatedMessage = [...state.normalMessage, ...allSessionMessage];
    return {
      ...state,
      normalMessage: updatedMessage,
      sessionId: action.payload.sessionId,
      isMessageLoading: false,
      numOfPages,
    };
  }

  if (action.type === SEND_MESSAGE) {
    return {
      ...state,
      normalMessage: [action.payload, ...state.normalMessage],
    };
  }

  if (action.type === DELETE_MESSAGE) {
    const messageId = action.payload
    const result = state.normalMessage.filter((item) => item._id !== messageId)
    return {
      ...state,
      normalMessage : [...result] 
    }
  }

  if (action.type === SET_MESSAGE_READ) {
    let updateNormalMessage = [...state.normalMessage];
    // let messageIds = [];
    // console.log(action.payload, 'messageIds')
    // action.payload?.msgArray?.map((item) => {
    //   if (action.payload.seenBy !== "agent") {
    //     messageIds.push(item._id);
    //   }
    //   return item._id;
    // });
    // console.log("messageIds", messageIds);
    // for (let i = 0; i < updateNormalMessage.length; i++) {
    //   console.log(updateNormalMessage[i]._id, updateNormalMessage[i].content);
    //   if (messageIds.includes(updateNormalMessage[i]._id)) {
    //     updateNormalMessage[i].isSeen = true;
    //   }
    // }
    if (action.payload.seenBy === "user") {
      let seenMessages = updateNormalMessage.map((item) => {
        return { ...item, isSeen: true };
      });
      return {
        ...state,
        normalMessage: seenMessages,
      };
    }
    return {
      ...state,
      normalMessage: updateNormalMessage,
    };
  }

  if (action.type === SET_SINGLE_MESSAGE_READ) {
    let updateNormalMessage = [...state.normalMessage];
    let newMessage = updateNormalMessage?.map((item) => {
      return item._id === action.payload ? { ...item, isSeen: true } : item;
    });

    return {
      ...state,
      normalMessage: newMessage,
    };
  }
  if (action.type === SET_IMAGE) {
    const { imagePath, content } = action.payload;
    let newMessage = [...state.normalMessage];
    let messageStructure = newMessage[0];
    let lastMessage = {
      ...messageStructure,
      image: imagePath,
      sender: "agent",
      isSeen: false,
      _id: "loadingImage",
      content,
    };
    newMessage.unshift(lastMessage);
    return {
      ...state,
      normalMessage: [...newMessage],
    };
  }
  if (action.type === REMOVE_IMAGE) {
    let newMessage = [...state.normalMessage];
    let filteredMessage = newMessage.filter(
      (item) => item._id !== "loadingImage"
    );
    return {
      ...state,
      normalMessage: [...filteredMessage],
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default inboxReducer;
