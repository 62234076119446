import React, { useEffect, useMemo, useState } from "react";
import HeaderBar from "../../components/reusable/HeaderBar/HeaderBar";
import AiLogsStyles from "../../assets/styledComponents/AiLogs";
import CustomReportTable from "../../components/Report/CustomReportTabel";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import useFetch from "../../hooks/useFetch";
import { apiTraining, buyAdditionalAITokens, getMLLogs } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import FullPageSpinner from "../../components/FullPageSpinner";
import moment from "moment";
import { Pagination } from "@mui/material";
import AiSelection from "./AiSelection";
import { formatNumberWithCommas } from "../../utils/helperFunction";
import Swal from "sweetalert2";
const AiLogs= () => {
  const {tenantId, tenantStore, profile } = useTenantContext()
  const [ trainingFunction, trainingLoading, trainingResult, trainingBendMessage]  = useFetch(apiTraining)
  const [ getMLLogFunction, mlLogLoading, mLLogResult, MLLogBackEndMessage]  = useFetch(getMLLogs)

  const [page, setPage]= useState(1)
  const [totalPage, setTotalPage] =useState(0)
  const [mlData, setMlData] = useState([]);
  const [tokenCount, setTokenCount] = useState(0);
  const [additionalTokenCount ,setAdditionalTokenCount] = useState()

  const [refillDateString, setRefillDate] = useState('');
  const [loading, setLoading] = useState(false)
  const limit = 10;

  const handleTrainClick = async(value) => {
    const {store} = value || {}
    const result = await trainingFunction({ Tenantid : tenantId, shop  : store})
  } 
  const columns = useMemo(
    () => [
      {
        Header: "Store",
        accessor: "store",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Date of training",
        accessor: "dataOfTraining",
      },
      // {
      //   Header: "Action",
      //   // id: "buttonColumn",
      //   accessor: "action",
      //  Cell: ({ row }) => (
      //     <PrimaryButton  onClick={() => handleTrainClick(row.values)}>Train</PrimaryButton>
      //   ),
      // },
    ],
    []
  );


  const calculateRefillTime = (date) => {
      // Parse the input date using moment
      const inputDate = moment(date, 'YYYY-MM-DD');
      
      // Calculate the refill date by adding 30 days to the input date
      const refillDate = inputDate.clone().add(30, 'days');

      const currentDate = moment()
      
      // Calculate the difference between the refill date and the input date
      const diff = moment.duration(refillDate.diff(currentDate));
      
      // Format the difference into the desired format
      const formattedDiff = `${diff.days()}d ${diff.hours()}h ${diff.minutes()}m`;

      // Return the formatted difference
      setRefillDate(formattedDiff);
  };

  const fetchMLLogs = async (store = '', status= "", type = "",  startValue = '', endValue = '') => {
    try {
      const result = await getMLLogFunction({tenantId,page, limit, store, status, type , startValue, endValue});
      const { totalPages} = result.data
      setTotalPage(totalPages)
      const data = result.data.logs.map((data) => {
        const {TenantID ,Store, Status, Type, Message, Date, Time } = data || {};
        const newData = {
          store: Store,
          status: Status,
          dataOfTraining: Date,
          action: "",
          type: Type
        };
        return newData;
      });
      setMlData(data)
      setTokenCount(result.data.aiTokenData.tokenCount);
      setAdditionalTokenCount(result.data.aiTokenData.additionalToken);
      calculateRefillTime(result.data.aiTokenData.date)
    } catch (error) {
      console.log(error);
    }
  };

  const buyAdditionalToken= async () => {
    const pricingPlan = profile?.token?.pricingPlan;
    setLoading(true);
    try {
      const buyTokenRes = await buyAdditionalAITokens({
        tenantId,
        selectedPlanId: pricingPlan._id,
        shop: profile.token.shop,
      });
      console.log("buy ticket res---", buyTokenRes);
      if (buyTokenRes.success) {
        const chargeId = buyTokenRes.chargeId;
        const paymentUrl = buyTokenRes.paymentUrl;
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err)
      await Swal.fire({
        icon: "error",
        title: "Recharge Ticket Quota",
        text: `Opps! Looks like your payment got failed. Please check your payment method or contact helpiq admin`,
        confirmButtonColor: "#8db178",
      });
    }
  }

  const handleBuyToken = async() => {
    const pricingPlan = profile?.token?.pricingPlan;
    const tenantId = profile.token.tenantId;
    const result = await Swal.fire({
      title: "Recharge AI Tokens",
      text: `Buy 100000 additional Tokens for $${pricingPlan.additionalTicketPrice}`,
      confirmButtonColor: "#8db178",
      showCancelButton: true,
      confirmButtonText: "Buy",
      denyButtonText: `Cancel`,
      reverseButtons: true,
    });

    if (result.isConfirmed) {
        await buyAdditionalToken()
    }
    
  }
  const  openModel = async()  => {
  }
  useEffect(() => {
    fetchMLLogs()
  }, [page])

  return (
    <AiLogsStyles>
      {MLLogBackEndMessage.msg && MLLogBackEndMessage.type === "error" && <CustomSnackbar payload={MLLogBackEndMessage} />}
        <HeaderBar
          title={"AI Logs"}
          showDescription={true}
          description="Provide the chatbot with the web URL of the content that you want to train its AI model on"
          hideSecondaryButton={true}
          hidePrimaryButton={true}
          // primaryButtonLabel={"Add FAQ"}
          // handlePrimaryButtonClick={handleButtonClick}
        />
        {/* {mlLogLoading ? <FullPageSpinner />  :  */}
        <div>
        <div className="training-container">
          <AiSelection fetchMLLogs={fetchMLLogs} />
          <div className="pending-tokens paper-card">
            <h3 className="heading token-heading">HelpIq Pending token</h3>
            <div className="underline"></div>
            <div className="remaining-token">
              <div>
                <p className="token-heading">Monthly AI Token :</p>
                <p className="token-count">{formatNumberWithCommas(tokenCount)}</p>
              </div>
              <div className="refill">
                <p className="sub-heading">Next token refill in </p>
                <p className="remaining-days">{refillDateString}</p>
              </div>
            </div>
            <div className="additional-token">
              <div>
                <p className="token-heading">Additional AI Token</p>
                <p className="token-count">{ additionalTokenCount  ?   formatNumberWithCommas(additionalTokenCount) : "0"}</p>
              </div>
              <PrimaryButton onClick={handleBuyToken}>Buy Tokens</PrimaryButton>
            </div>
          </div>
        </div>
        <div className="table paper-card">
          <CustomReportTable
            columns={columns}
            data={mlData}
            tableLoading={false}
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            mlLogLoading={mlLogLoading}
          />

        </div>

        </div>
        {/* } */}
    </AiLogsStyles>
  );
};

export default AiLogs;
