import React, { useEffect, useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Swal from "sweetalert2";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ChatStyles from "../../../assets/styledComponents/channelConfiguration/chat/Chat";
import OrderStatusScreen from "./OrderStatusScreen";
import {
  useChannelContext,
  CHAT_INITIAL_CONFIG,
  CHAT_INITIAL_CONFIG_ERROR,
} from "../../../context/channelConfigurationContext/channelContext";
import { useTenantContext } from "../../../context/TenantContext";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";
import FullPageSpinner from "../../../components/FullPageSpinner";
import Modal from "../../../components/reusable/DialogModal/Modal";
import ViewCodeSnippet from "./ViewCodeSnippet";
import HeaderBar from "../../../components/reusable/HeaderBar/HeaderBar";
import { CHAT_WIDGET_TABS } from "./helper";
import { getKey } from "../../../utils/helperFunction";
import useClipboard from "../../../hooks/useClipboard";
import {
  getAllTenantStore,
  getAllChatWidget,
  createChatWidget,
  deployWidget,
} from "../../../service/service";
import AppearanceScreen from "./AppearanceScreen";
import _ from "lodash";
import ChatBubble from "./ChatBubble";

const Chat = () => {
  const {
    postChatWidget,
    showOrderStatus,
    setShowOrderStatus,
    getChannelConfiguration,
    appearance,
    responseMessage,
    // isLoading,
    // scriptUrl,
    // disableSaveBtn,
    widgetFormattedList,
    setWidgetFormattedList,
    widgetFormattedListError,
    setWidgetFormattedListError,
    selectedWidgetShop,
    setSelectedWidgetShop,
    openTab,
    setOpenTab,
    handleTrackOrderClick,
    trackOrderCount,
    showAppearanceScreen,
    currentTabValue,
    setCurrentTabValue,
    setShowProgress,
    hideDeployButton,
    setError, 
  } = useChannelContext();

  const { tenantId } = useTenantContext();

  const store = localStorage.getItem("shop");

  const { themeColor } = appearance;
  const initialState = [...CHAT_WIDGET_TABS];
  const [fourthButtonModalOpen, setFourthButtonModalOpen] = useState(false);
  // const [openTab, setOpenTab] = useState(initialState);
  const [openModel, setOpenModel] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [storeTabs, setStoreTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [scriptUrl, setScriptUrl] = useState(true);
  const [tenantStoreId, setTenantStoreId] = useState("");
  const [clipboardValue, setClipboardValue] = useState();
  const [isCopied, copyToClipboard] = useClipboard(clipboardValue);
  const [open, setOpen] = React.useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [originalWidgetList, setOriginalWidgetList] = useState([]);
  const [formattedWidgetList, setFormattedWidgetList] = useState({});
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  // const [selectedWidget, setSelectedWidget] =
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setTenantStoreId(storeTabs[newValue]._id);
    setSelectedWidgetShop(storeTabs[newValue].shop);
    setScriptUrl(widgetFormattedList[storeTabs[newValue].shop].scriptUrl);
    setDisableSaveBtn(
      widgetFormattedList[storeTabs[newValue].shop].scriptUrl ? true : false
    );
  };
  useEffect(() => {
    if (originalWidgetList.length === 0) return;
    const saveBtnValue = _.isEqual(
      {
        ...originalWidgetList?.[0]?.appearance,
        ...originalWidgetList?.[0]?.contactForm,
        ...originalWidgetList?.[0]?.orderStatus,
      },
      {
        ...widgetFormattedList[selectedWidgetShop]?.appearance,
        ...widgetFormattedList[selectedWidgetShop]?.contactFormValue,
        ...widgetFormattedList[selectedWidgetShop]?.orderStatus,
      }
    );
    setDisableSaveBtn(saveBtnValue);

    console.log(
      "activeTab ",
      originalWidgetList[0].contactForm,
      widgetFormattedList[selectedWidgetShop].contactFormValue,
    );
    console.log({ saveBtnValue, activeTab });
    console.log({ save: originalWidgetList });
  }, [widgetFormattedList]);

  const fetchStores = async () => {
    if (!tenantId) return;
    const tabsRes = await getAllTenantStore(tenantId);
    if (tabsRes.success) {
      setStoreTabs(tabsRes.data);
      setActiveTab(0);
      setSelectedWidgetShop(tabsRes.data[0].shop);
      return tabsRes.data;
    }
  };

  console.log("Context UPdate Log", widgetFormattedList);
  const getFormattedWidgetsData = async (widgetsList, storeTabsList) => {
    let newFormattedWidgetList = {};
    let newFormattedWidgetListError = {};
    const storeId = storeTabsList[activeTab]._id
    const url = widgetsList.filter((item) => {
     return  item.tenantStoreId === storeId 
    })
    setScriptUrl(url[0]?.scriptUrl);
    console.log("Store Tabs", [...storeTabsList]);

    await Promise.all(
      storeTabsList.map((storeTabData) => {
        const iterativeShop = storeTabData.shop;
        const iterativeShopId = storeTabData._id;
        const isWidgetDataExistsForShop = widgetsList.findIndex(
          (widgetIterativeData) =>
            widgetIterativeData.tenantStoreId === iterativeShopId
        );
        console.log("Is Widget Data Exists ", isWidgetDataExistsForShop);
        let widgetDataForShop;
        let widgetDataForShopError;
        if (isWidgetDataExistsForShop === -1) {
          // Set Initial Config
          widgetDataForShop = CHAT_INITIAL_CONFIG;
          widgetDataForShopError = CHAT_INITIAL_CONFIG_ERROR;
        } else {
          let widgetListData = widgetsList[isWidgetDataExistsForShop];
          let shopData = {
            ...CHAT_INITIAL_CONFIG,
            ...widgetListData,
            appearance: {...widgetListData.appearance, orderStatusList  : CHAT_INITIAL_CONFIG.appearance.orderStatusList},
            contactFormValue: widgetListData.contactForm,
            queryList: widgetListData.contactForm.typeOfQuery,
            // queryList: widgetListData.query,
          };
          widgetDataForShop = shopData;
          widgetDataForShopError = CHAT_INITIAL_CONFIG_ERROR
        }
        newFormattedWidgetList[iterativeShop] = widgetDataForShop;
        newFormattedWidgetListError[iterativeShop] = widgetDataForShopError;
      })
    );

    setWidgetFormattedList(newFormattedWidgetList);
    setWidgetFormattedListError(newFormattedWidgetListError);

    console.log("New Formatted Widget List", newFormattedWidgetList);
  };

  const fetchChatWidget = async (tab) => {
    // await getChannelConfiguration(tenantId);
    const updatedStoreTabs = await fetchStores();
    if (tab) setActiveTab(tab)
    const allChatWidgetRes = await getAllChatWidget(tenantId);
    console.log(
      "save",
      allChatWidgetRes.data,
      widgetFormattedList[selectedWidgetShop]?.contactFormValue
    );

    console.log("Res ----", allChatWidgetRes);

    if (
      allChatWidgetRes.success &&
      allChatWidgetRes.data &&
      Array.isArray(allChatWidgetRes.data)
    ) {
      setTenantStoreId(updatedStoreTabs[0]?._id);
      setOriginalWidgetList(allChatWidgetRes.data);
      await getFormattedWidgetsData(allChatWidgetRes.data, updatedStoreTabs);
      setIsLoading(false);
      // const selectedWidgetData = allChatWidgetRes.data.filter((chatWidgetData) => chatWidgetData.shop === storeTabs[activeTab].shop);

      // if (selectedWidgetData && selectedWidgetData.length) {
      //   console.log("Selected Widget Data Found in DB ", selectedWidgetData[0]);

      // } else {
      //   console.log("Selected Store Widget Doesn't exists")
      // }
    }
  };

  const handleClick = (e, tabClicked) => {
    const currentTab = e.target.innerText;
    setCurrentTabValue(currentTab);
    console.log({ trackOrderCount });
    if (trackOrderCount[trackOrderCount.length - 1] !== "card") {
      handleTrackOrderClick();
    }
    setOpenTab((prev) => {
      const result = prev.map((item) => {
        return item.tab === tabClicked
          ? { ...item, isOpen: true }
          : { ...item, isOpen: false };
      });
      console.log({ result });
      return result;
    });
    // setOpenTab((prev) => {
    //   const result = initialState.map((item) => {
    //     return item.tab === currentTab
    //       ? { ...item, isOpen: item.tab === currentTab }
    //       : { ...item, isOpen: false };
    //   });
    //   return result;
    // });
  };

  const handleSnippet = () => {
    setOpenModel(true);
  };

  const handleFourthButtonClick = () => {
    setOpenSecondModal(true);
    console.log("openSecondModal state:", openSecondModal);
  };

  const handleSave = async () => {
    // fetchChatWidget(activeTab)
    const currentShopAppearance = widgetFormattedList[selectedWidgetShop].appearance;
    const currentShopOrderStatus = widgetFormattedList[selectedWidgetShop].orderStatus;

    let isError = false
    if (!currentShopAppearance.brandName) {
      setError("appearance", "brandName" , "Please Enter Brand Name")
      isError  = true
    }
    // if (!currentShopAppearance.statusText) {
    //   setError("appearance", "statusText" , "Please Enter Status Text")
    //   isError  = true
    // }
    if (!currentShopAppearance.greetingMessage) {
      setError("appearance", "greetingMessage" , "Please Enter Greeting message")
      isError  = true
    }
    if (!currentShopAppearance.introMessage) {
      setError("appearance", "introMessage" , "Please Enter Intro message")
      isError  = true
    }
    if (!currentShopOrderStatus.title) {
      setError("orderStatus", "title" , "Please Enter Title")
      isError  = true
    }
    if (!currentShopOrderStatus.message) {
      setError("orderStatus", "message" , "Please Enter Message")
      isError  = true
    }
    if (isError) {
      return
    }
    setShowProgress(false)
    setLoading(true);
    try {
      // const result = await postChatWidget(store, tenantId, tenantStoreId);
      const payload = {
        tenantId,
        tenantStoreId,
        ...widgetFormattedList[selectedWidgetShop],
        contactForm: widgetFormattedList[selectedWidgetShop].contactFormValue,
      };
      const result = await createChatWidget(payload);
      console.log({ result: result.data._doc });

      if (result?.data?._doc) {
        setScriptUrl(result?.data?._doc?.scriptUrl);
        setWidgetFormattedList((prev) => {
          let updateValue = prev;
          updateValue[selectedWidgetShop] = {...result?.data?._doc, 
            appearance : {...result?.data?._doc.appearance, orderStatusList  : CHAT_INITIAL_CONFIG.appearance.orderStatusList},
          };
          return updateValue;
        });
        setOriginalWidgetList([result.data._doc])
      } else {

        setScriptUrl(result?.data?.scriptUrl);
        setWidgetFormattedList((prev) => {
          let updateValue = prev;
          updateValue[selectedWidgetShop] = {...result?.data, 
            appearance : {...result?.data?.appearance, orderStatusList  : CHAT_INITIAL_CONFIG.appearance.orderStatusList},
          };
          return updateValue;
        });
      }
      setLoading(false);
      setDisableSaveBtn(true);

    } catch (error) {
      setLoading(false);
      console.log(error.response.data);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    copyToClipboard();
  };

  useEffect(() => {
    // fetchStores();
    fetchChatWidget();
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      setShowOrderStatus(false);
    } else {
      setOpenTab((prev) => {
        let updatedTab = [...prev];
        updatedTab[0].isOpen = false;
        // updatedTab[2].isOpen = true;
        // updatedTab[2].mobileComponent = <OrderStatusScreen />;
        return updatedTab;
      });

      setShowOrderStatus(false);
    }
  }, [showOrderStatus]);

  const clipboardIcon = (
    <ChatStyles>
      <>
        <Tooltip title={!open ? "Copy Snippet" : "Snippet Copied"}>
          <div className="clipboard">
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <IconButton>
                  <ContentPasteIcon onClick={handleTooltipOpen} />
                </IconButton>
              </div>
            </ClickAwayListener>
          </div>
        </Tooltip>
      </>
    </ChatStyles>
  );

  const [backendMessage, setBackendMessage] = useState({
    msg: "",
    type: "",
  });
  const hideThirdButton = widgetFormattedList[selectedWidgetShop]?.isDeployed 
  const [thirdButtonLoading, setThirdButtonLoading] = useState(false);
  const handleAutomaticallyDeployment = async () => {
    setThirdButtonLoading(true);

    try {
      const deploymentResult = await deployWidget({
        shop: selectedWidgetShop,
        scriptUrl: scriptUrl,
        widgetId : widgetFormattedList[selectedWidgetShop]?._id
      });
      if (deploymentResult.data) {
        const redirectUrl = deploymentResult.data
        window.open(redirectUrl, '_blank');
        setThirdButtonLoading(false);
        return;
      }else {
      Swal.fire({
        title: "Something went wrong !",
        text: "Please try again later or Contact HelpIq Admin.",
        confirmButtonColor: "#8db178",
        icon: "error",
      });
      }

      // if (deploymentResult) {
      //   Swal.fire({
      //     title: "Success!",
      //     text: "Deployment was successful.",
      //     confirmButtonColor: "#8db178",
      //     icon: "success",
      //   });
      // }
      setThirdButtonLoading(false);
      // hideDeployButton(true)
      // setTimeout(() => {
      //   setDisableSaveBtn(true)
      // },(500) );
      // fetchChatWidget(activeTab)
    } catch (error) {
      console.log(error.response.data);

      Swal.fire({
        title: "Something went wrong !",
        text: "Please try again later or Contact HelpIq Admin.",
        confirmButtonColor: "#8db178",
        icon: "error",
      });

      setThirdButtonLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <ChatStyles themeColor={themeColor}>
          {responseMessage.msg && <CustomSnackbar payload={responseMessage} />}
          {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
          <Modal
            name="Code Snippet"
            open={openModel}
            onClose={() => setOpenModel(false)}
            navChildren={clipboardIcon}
          >
            <ViewCodeSnippet
              setClipboardValue={setClipboardValue}
              scriptUrl={scriptUrl}
            />
          </Modal>

          <Modal
            name="Need help ?"
            open={openSecondModal}
            onClose={() => setOpenSecondModal(false)}
          >
            <div style={{ width: "500px" ,marginLeft:"12px"}}>
              <span>
                <a
                  href="https://helpiq.app/support-channel/how-to-set-up-live-chat-on-helpiq/"
                  style={{ display: "block", margin: "10px 0", padding: "5px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                How to configure chat ?
                </a>
              </span>
            </div>
          </Modal>

          <HeaderBar
            thirdButton={"Deploy"}
            thirdButtonLoading={thirdButtonLoading}
            hideThirdButton={scriptUrl ?  false : true}
            handleThirdButtonClick={handleAutomaticallyDeployment}
            // showIcon={true}
            // icon={<QuestionMarkIcon/>}
            fifthButton = {<a
              href="https://helpiq.app/support-channel/how-to-set-up-live-chat-on-helpiq/"
              className="helper-link"
              target="_blank"
              rel="noreferrer"
            >
            How to deploy chatbot in store?
            </a>}

            hideFourthButton={true}
            // handleIconClick={handleFourthButtonClick}
            title={"Channel Configuration"}
            primaryButtonLabel={scriptUrl ? "Update" : "Save"}
            // secondaryButtonLabel={"Code Snippet"}
            handlePrimaryButtonClick={handleSave}
            // handleSecondaryButtonClick={handleSnippet}
            primaryButtonDisable={disableSaveBtn}
            primaryButtonLoading={loading}
            hideSecondaryButton={true}
          />

          <div>
            <div className="store-tab-container">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                {console.log("Store Tabs --", storeTabs)}
                {storeTabs.map((tabData) => (
                  <Tab
                    label={`${tabData.shop || "New Account"}  ${
                      tabData.isDefault ? "- Default" : ""
                    }`}
                    key={`${tabData.shop || "New Account"}  ${
                      tabData.isDefault ? "- Default" : ""
                    }`}
                  />
                ))}
              </Tabs>
            </div>
            <div className="wrapper chat-setting-container">
              <div className="personalize-wrapper">
                <div className="personalize-heading">
                  <h4>Personalize Your Chat Widget</h4>
                  <p>Customize the widget as per your interest</p>
                </div>
                <div className="theme-container">
                  <div>
                    <ul className="theme-heading">
                      {openTab.map(({ tab, isOpen }, index) => {
                        return (
                          <li
                            key={getKey("openTab", tab, index)}
                            // className={isOpen && "tab-active"}
                            className={isOpen ? "tab-active" : ""}
                            onClick={(e) => handleClick(e, tab)}
                          >
                            {tab}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="appearance-container">
                      {openTab.map((item, index) => {
                        console.log("clicked");
                        console.log({ item });
                        const { isOpen, component, tab } = item;
                        return (
                          <React.Fragment
                            key={getKey("openTab", "item", index)}
                          >
                            {isOpen && component}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="appearance-screen">
                {showAppearanceScreen ? (
                  <AppearanceScreen type={currentTabValue} />
                ) : (
                  <ChatBubble />
                )}
              </div>
            </div>
          </div>
        </ChatStyles>
      )}
    </>
  );
};

export default Chat;
