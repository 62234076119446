import styled from "styled-components";
const MessageConversationStyles = styled.div`
  /* height: 100%; */

    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
  .date {
    text-align: center;
    color: #666;
  }
  .ai-heading {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap :1rem;
    align-items: center;

  }
  .chat-receiver {
    width: fit-content;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 1rem;
    box-shadow: 0px 2px 4px 0px #00000026;
    margin: 1rem 0;
    margin-left: 1rem;
  }
  .chat-sender {
    width: fit-content;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: 1rem;
    box-shadow: 0px 2px 4px 0px #00000026;
    margin: 1rem 0;
    margin-left: auto;
  }
  .sender {
    text-align: right;
  }
  .receiver {
    text-align: left;
  }
  .conversation {
    margin-top: 1rem;
    /* height: 68vh; */
     /* max-height: 100%; */
    /* padding-bottom: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse; */
  }

  .center-spinner {
    text-align: center;
  }
  .empty-msg {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-input-container {
    position: absolute;
    bottom: 0rem;
    width: 660px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .message-end {
    padding-bottom: 1rem;
  }
  .message-container{
    /* background-color: yellow; */
  }
  .conversation-container {
  }

  .current-date {
    text-align: center;
  }
  .file-details {
    max-width: 160px;
  }
  .file-name {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .ai-chat-completed {
    display: flex;
    align-items: center;
    text-align: center;
    width: 500px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
  }

  .ai-chat-completed::before,
  .ai-chat-completed::after {
    content: "";
    flex-grow: 1;
    border-bottom: 1px solid black; /* Adjust the color and thickness */
    margin: 0 20px; /* Adjust spacing as needed */
  }



  /* @media (min-width: 1600px) {
    .conversation{
      height:  80vh;
    }
  }
  @media (min-width: 2800px) {
    .conversation{
      background-color: yellow;
      height:  90vh;
    }
  } */
`;
export default MessageConversationStyles;
