import React, { forwardRef, useEffect, useState } from "react";
import { MessagePreviewStyles } from "../../assets/styledComponents/inboxMessage";
import PropTypes from "prop-types";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import CustomAvatar from "../reusable/CustomAvatar";
import {
  getFullDate,
  getFullShortDate,
  getLastMessageTime,
  getSentimentEmoji
} from "../../utils/helperFunction";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import SnoozeIcon from "@mui/icons-material/Snooze";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import StarIcon from "@mui/icons-material/Star";
import { useTenantContext } from "../../context/TenantContext";
import CustomerReason from "./CustomerReason";
import "./styles.css";

const MessagePreview = forwardRef((props, ref) => {
  const {
    latestMessage,
    unReadMessage,
    isRead,
    isSnooze,
    snoozeTime,
    _id,
    userName,
    ticketId,
    label,
    masterLabel,
    priority,
    channelName,
    customerInfo,
    allLabels,
    storeName,
    position,
    index,
    review
  } = props;
  // const labelNames = masterLabel?.map((l) => l.labelName).join(", ");
  // console.log("this is selected labelNames",labelNames)
  const messageIcon =
    channelName === "instagram" ? (
      <InstagramIcon  sx={{color : '#E4405F'}}/>
    ) : channelName === "mail" ? (
      <MailOutlineOutlinedIcon />
    ) : channelName === "whatsapp" ? (
      <WhatsAppIcon sx={{ color: "green" }} />
    ) : channelName === "facebook" ? (
      <FacebookIcon sx={{color : '#316FF6'}}/>
    ) : (
      <ChatBubbleOutlineIcon />
    );

  const { previewActive, setPreviewActive, setSelectedShop, userId , tenantId} = useTenantContext();
  const {
    setCurrentChat,
    socket,
    setMessageSent,
    setCurrentSessionData,
    setRefreshNotification,
    setName,
    setBackendMessage,
    setText,
    setError,
    setSearchQuery,
    clearStoreData,
    setIsMessagePrivate,
    setQuickReplySelected,
    setPage,
    setNormalMessage, 
    setIsProductAdded,
    setSelectedComment,
    currentSessionData
  } = useInboxContext();

  const [labelNames, setLabelNames] = useState();
  const name = customerInfo?.["First Name"]
    ? customerInfo?.["First Name"]
    : "Unknown";

  const handleMessageClick = async (name, _id, review) => {
//     setCurrentChat({ name, sessionId: _id });
    setSelectedShop(storeName);
    socket?.emit('AGENT_SEEN_MESSAGE', _id, tenantId, userId);
    setCurrentChat({ name, sessionId: _id, messageType : channelName, isReview : review});
    // socket?.emit("JOIN_CHAT", _id);
    // socket?.emit("UPDATE_MESSAGE_IS_SEEN", _id, "agent", userId);
    // socket?.emit("MESSAGE_OPENED_BY_AGENT_SENT", _id, "agent");
    setTimeout(() => {
      setRefreshNotification((prev) => !prev);
    }, 0);
    setName(name);
    setCurrentSessionData(props);
    setText("");
    setSearchQuery(""),
    setMessageSent((prev) => !prev);
    setBackendMessage({ msg: "", type: "" });
    setError("");
    setIsProductAdded(false)
    clearStoreData();
    setPreviewActive({
      isActive: true,
      sessionId: _id,
    });
    setIsMessagePrivate(false)
    setQuickReplySelected(false)
    setPage(2)
    setNormalMessage()
    setSelectedComment(false)
  };

  const formatSelectedLabels = async () => {
    const labelNamesArray = await Promise.all(
      label.map((labelId) => {
        const labelName = allLabels.filter(
          (labelData) => labelData._id === labelId
        )[0].labelName;
        return labelName;
      })
    );

    setLabelNames(labelNamesArray.join(", "));
  };
  useEffect(() => {
    formatSelectedLabels();
  }, [masterLabel, label]);

  const unReadMessageCount = unReadMessage?.filter((item) => {
    const { sender, isSeen, isPrivate, userId: unReadMessageUserId } = item;
    return (
      (sender !== "agent") & (isSeen === false) ||
      (sender === "agent" && isPrivate && unReadMessageUserId !== userId)
    );
  });


  const snoozeTimeCalculate = (endTime) => {
    const currentDate = new Date();
    const startTime = moment(currentDate);
    const hoursDifference = endTime.diff(startTime, "minutes");
    if (hoursDifference >= 60 && hoursDifference <= 120) {
      return (hoursDifference / 60).toFixed(1) + " hrs";
    }
    if (hoursDifference < 0) {
      return;
    }
    if (hoursDifference < 60) {
      return hoursDifference  + " min";
    }

    return getFullShortDate(endTime);
  };

  const [showSelectedLabel, setShowSelectedLabel] = useState(false);
  const handleMouseEnter = () => {
    setShowSelectedLabel(true);
    console.log(label);
  };
  const handleMouseLeave = () => {
    setShowSelectedLabel(false);
  };

  return (
    <MessagePreviewStyles>
      <div
        className={`message-preview-container`}
        onClick={() => handleMessageClick(name, _id, review)}
      >
        <div className="profile">
          <CustomAvatar name={name} />
        </div>
        <div className="message-content">
          <div className="top">
            <h3 className="name">{name}</h3>
            {/* {Boolean(priority) && <span className="star-icon"></span>} */}
            <span className="message-icon">
              <span>{messageIcon}</span>
            </span>
            {/* <div className="icons">
              {priority && <StarIcon style={{ color: "#729B5A" }} />}
              {label.length >= 1 && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="label-icon"
                >
                  <LabelOutlinedIcon style={{ color: "#729B5A" }} />
                </div>
              )}
              {messageIcon}
            </div> */}
          </div>
          <p className="ticket-id">Ticket Id: #{ticketId}</p>
          {/* <div className="message truncate">{latestMessage?.content}</div> */}
          {/* {
            props.customerSentimentAI && (
              <div className="customer-info-container">
                 <span className="customer-sentiment" title={`Sentiment: ${props.customerSentimentAI}`}>
                  <span className="sentiment-emoji">
                    {getSentimentEmoji(props.customerSentimentAI?.toLowerCase())}
                  </span>
                </span>
                <span className="customer-reason" title={`Reason: ${props.customerReasonAI}`}>
                  {props.customerReasonAI}
                </span>
              </div>
            )
          } */}

          {
            props.customerSentimentAI && (
              <div className="customer-info-container">
                 <span className="customer-sentiment" title={`Sentiment: ${props.customerSentimentAI}`}>
                  <span className="sentiment-emoji">
                    {getSentimentEmoji(props.customerSentimentAI?.toLowerCase())}
                  </span>
                </span>
                <CustomerReason customerReasonAI={props.customerReasonAI} />
              </div>
            )
          }
         {/* { props.customerSentimentAI && <div>{getSentimentEmoji(props.customerSentimentAI.toLowerCase())}{props.customerReasonAI}</div>} */}
          <div className="bottom-msg-bar">
            <div className="common-icons-container">
              <Tooltip title={getLastMessageTime(latestMessage?.createdAt)}>
                <span className="time truncate">
                  {getLastMessageTime(latestMessage?.createdAt)}
                </span>
                </Tooltip>
              <span className="star-icon">
                {priority ? (
                  <StarIcon style={{ color: "#729B5A", fontSize: "18px" }} />
                ) : (
                  <StarIcon style={{ visibility: "hidden" }} />
                )}
              </span>
              {label?.length >= 1 && (
                <span
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="label-icon"
                  style={{ display: "flex" }}
                >
                  <Tooltip title={labelNames}>
                    <LabelOutlinedIcon
                      style={{ color: "#729B5A", fontSize: "18px" }}
                    />
                  </Tooltip>
                </span>
              )}
              <div className="snooze-icon">
                {isSnooze && (
                  <span className="snooze-container">
                    <SnoozeIcon />
                    <span className="snooze-label">
                      {snoozeTimeCalculate(moment(snoozeTime))}
                    </span>
                  </span>
                )}
              </div>
            </div>
            {isRead ? (
              <span className="label">
                {`${
                  unReadMessage?.length === 0
                    ? "1"
                    : unReadMessage.filter((item) => item.sender !== "agent")
                        .length
                } new`}
              </span>
            ) : (
              unReadMessageCount?.length > 0 && (
                <span className="label">
                  {`${unReadMessageCount?.length} new`}
                </span>
              )
            )}
          </div>
          {/* <div className="bottom">
            <span className="days">
              {getLastMessageTime(latestMessage?.createdAt)}
            </span>
            <div className="icons-container">
                <div>
                {priority && <div className="star-icon">
                <StarIcon style={{ color: "#729B5A", fontSize: "18px" }} />
              </div>}
              {label.length >= 1 && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="label-icon"
                >
                  <LabelOutlinedIcon
                    style={{ color: "#729B5A", fontSize: "18px" }}
                  />
                </div>
              )}
                </div>

              <span className="label">
                    {`5 new`}
              </span>

              {isRead ? (
                <span className="label">
                  {`${unReadMessage?.length === 0
                    ? "1"
                    : unReadMessage.filter((item) => item.sender !== "agent")
                      .length
                    } new`}
                </span>
              ) : (
                unReadMessageCount?.length > 0 && (
                  <span className="label">
                    {`${unReadMessageCount?.length} new`}
                  </span>
                )
              )}
            </div>
            <span className="message-icon">
              {isSnooze && (
                <span className="snooze-container">
                  <SnoozeIcon />
                  <span className="snooze-label">
                    {snoozeTimeCalculate(moment(snoozeTime))}
                  </span>
                </span>
              )}
            </span>
          </div> */}
        </div>
      </div>
    </MessagePreviewStyles>
  );
});

export default MessagePreview;

MessagePreview.propTypes = {
  name: PropTypes.string,
  messagefrom: PropTypes.string,
  ticketId: PropTypes.string,
  message: PropTypes.array,
};
MessagePreview.displayName = "MessagePreview";
