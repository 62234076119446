import React, { useEffect, useState } from "react";
import {
  // ArrowBack as ArrowBackIcon,
  MoreVert as MoreVertIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material";
import { Avatar, Chip } from "@mui/material";
import TicketSummaryModal from "../modal/TicketSummaryAiModal";
import { TicketSummaryStyles } from "../../assets/styledComponents/message";
import TicketSummaryImg from "../../assets/images/overview.svg";

import ThreeDotOptions from "./ThreeDotOptions";
import AssignToModal from "./AssignToModal";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";

import CustomSnackbar from "../snackbar/CustomSnackbar";
import { getAllMessages, getTicketSummary, sendMessage, updateStatus } from "../../service/service";
import { closingEmailMessage, customerFeedbackEmail, stringAvatar } from "../../utils/helperFunction";
import Swal from "sweetalert2";

const TicketSummary = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketSummaryLoading , setTicketSummaryLoading] = useState(false)
  const [modalName, setModalName] = useState("Assign To");
  const [backendMessage, setBackendMessage] = useState({ msg: "", type: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [ticketSummary , setTicketSummary] = useState('')
  const {
    socket,
    normalMessage,
    setCurrentSessionData,
    currentSessionData,
    refreshChatSessions,
    setNormalMessage,
  } = useInboxContext();

  const { userId: currentUserId, tenantId  } = useTenantContext();
  
  const { _id, assignTo, assignUser, taggedUser , channelName} = currentSessionData;
  const isChatCompleted = currentSessionData.status === "complete";

  const isNotAssignedUser =
    currentUserId=== assignTo ? false : taggedUser?.includes(currentUserId);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleComplete = async () => {
    try {
      const result = await updateStatus({ sessionId: _id, status: "complete" });
      if (channelName === "mail") {
        const { fromEmail, fromName, toEmail, _id , ticketId} = currentSessionData || {};
        const findLastUserMessage = normalMessage.filter((item) => {
          return item.sender === "user";
        });
        const latestSubject = findLastUserMessage[0].emailSubject;
        const senderName = toEmail?.split("@")[0] || "";
        const currentUrl = new URL(window.location.href);
        const baseUrl = currentUrl.origin;
        let payload = {
          userId : currentUserId,
          tenantId,
          sender: "agent",
          chatSession: _id,
          channelName,
          emailConfig: {
            fromEmail: toEmail,
            fromName: senderName,
            toEmail: fromEmail,
            toName: fromName,
            subject: latestSubject || "Ticket Closed",
            textContent : `Your ticket ${ticketId} has been closed`,
            htmlContent: customerFeedbackEmail({id : _id, baseUrl }),
            attachment: [],
          },
        };
        const result = await sendMessage(payload);
      }
      if (channelName === 'facebook' || channelName === 'instagram' || channelName === 'whatsapp') {
        const currentUrl = new URL(window.location.href);
        const baseUrl = currentUrl.origin;
        const payload = {
          userId : currentUserId,
          tenantId,
          chatSession : _id,
          sender : "agent",
          channelName,
          content: `Thank you for reaching out to us! We're glad to have resolved your issue. Please take a moment to rate your experience: ${baseUrl}/customer-feedback?id=${_id}. Your feedback helps us improve. 😊`,
        }
        const result = await sendMessage(payload);
      }
      setBackendMessage({ msg: "Chat Marked as completed", type: "success" });
      setCurrentSessionData({});
      refreshChatSessions();

      setNormalMessage();
      socket?.emit("MARK_AS_COMPLETE", _id, tenantId);
    } catch (error) {
      console.log(error);
    }
  };

  // const updateWidthClass = () => {
  //   const elementChatContainer = document.getElementById("chat-conatiner");
  //   // console.log("I am Before if", emailChatContainer, elementToUpdate)

  //   if (elementChatContainer) {
  //     const width = elementChatContainer.offsetWidth;
  //     // Remove existing width-based classes

  //     setTimeout(() => {
  //       const elementToUpdate = document.getElementById(
  //         "ticket-summary-right-section"
  //       );
  //       const elementToUpdate2 = document.getElementById("conversation");

  //       if (width < 600 && width !== null) {
  //         elementToUpdate?.classList.add("mobile-ticket-summary-right-side");
  //         elementToUpdate2?.classList.add("mobile-conversation");
  //       } else {
  //         elementToUpdate?.classList.remove("mobile-ticket-summary-right-side");
  //         elementToUpdate2?.classList.remove("mobile-conversation");
  //       }
  //     }, 500);
  //   }
  // };
  const handleTicketSummaryClick = async () => {
    setIsModalOpen(true);
    setTicketSummaryLoading(true);
    try {
      const messages = await getAllMessages(currentSessionData?._id);
      const fullText= messages.data.map(obj => {
        console.log("Ticket Summary message-", obj);
        return obj?.content?.replace(/[^\w\s]/gi, '').replace(/\s+/g, '') || ''
      }).join('');
      
      const result = await getTicketSummary({
        tenantid: tenantId,
        fulltext: fullText,
      }, process.env.REACT_APP_AI_API_KEY);

      setTicketSummary(result?.Summary_text);
      setTicketSummaryLoading(false);
      if (result.Status === 404) {
        Swal.fire({
          title: "Error!",
          text: "The AI Token allocated for this month has been utilized",
          icon: "error",
          confirmButtonColor: "#d33",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
        confirmButtonColor: "#d33",
      });
      setTicketSummaryLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    // Initial width update
    // updateWidthClass();

    // Update width on window resize
    // window.addEventListener("resize", updateWidthClass);
    // Cleanup event listener on component unmount
    return () => {
      // window.removeEventListener("resize", updateWidthClass);
    };
  }, []);

  useEffect(() => {
    // updateWidthClass();
  }, [currentSessionData?.ticketId]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <TicketSummaryStyles>
      {normalMessage?.length === 0 ? null : (
        <div className="container">
          {/* <div className="left">
          </div> */}
          <div id="ticket-summary-right-section" className="right">
            <div className="ticket-id">
              Ticket Id : <span>#{currentSessionData?.ticketId}</span>
            </div>
            <div
              className="ticket-summary-icon"
              onClick={handleTicketSummaryClick}
            >
              <div className="img">
                {" "}
                <img src={TicketSummaryImg} alt="" />{" "}
              </div>
              <div className="dfc">
                <h4 className="heading">Ticket Summary</h4>
                <span>Powered by AI</span>
              </div>
            </div>
            <TicketSummaryModal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              ticketSummary={ticketSummary}
              ticketSummaryLoading={ticketSummaryLoading}
            />
            {!isChatCompleted ? (
              <>
                {assignTo && assignUser ? (
                  assignUser.profileImageUrl ? (
                    <Avatar
                      className="avatar-img"
                      src={assignUser.profileImageUrl}
                    ></Avatar>
                  ) : (
                    <Avatar
                      className="avatar-img"
                      {...stringAvatar(assignUser.name)}
                    ></Avatar>
                  )
                ) : (
                  <button
                    className="assign-to"
                    onClick={() => setIsOpenModal(!isOpenModal)}
                  >
                    <AddCircleOutlineIcon />
                    <span>Assign to</span>
                  </button>
                )}
              </>
            ) : (
              <Chip
                label="Completed"
                color="primary"
                sx={{ margin: "2px 4px" }}
              ></Chip>
            )}

            <AssignToModal
              modalName={modalName}
              handleCloseModal={handleCloseModal}
              isOpenModal={isOpenModal}
              setBackendMessage={setBackendMessage}
            />
            <div
              className={`complete-btn ${
                isChatCompleted ||
                !assignTo ||
                currentSessionData.assignTo !== currentUserId
                  ? "hide"
                  : ""
              }`}
            >
              <PrimaryButton variant={"contained"} onClick={handleComplete}>
                Complete
              </PrimaryButton>
            </div>

            {!isNotAssignedUser && (
              <button
                style={{ marginLeft: 5 }}
                aria-describedby={id}
                onClick={handleClick}
                className="three-dot-btn"
              >
                <MoreVertIcon />
              </button>
            )}
            {!isNotAssignedUser && (
              <ThreeDotOptions
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={open}
                id={id}
                setIsOpenModal={setIsOpenModal}
                setModalName={setModalName}
                setBackendMessage={setBackendMessage}
              />
            )}
          </div>
        </div>
      )}
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
    </TicketSummaryStyles>
  );
};

export default TicketSummary;
