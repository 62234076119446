import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { createReview, isAlreadyReviewed } from "../service/service";
import { useLocation, useNavigate } from "react-router-dom";
import feedBackImage from "../assets/images/feedback.png";
import { Box, CircularProgress } from "@mui/material";

const emojis = [
  { emoji: "😖", label: "Worst", rating: 1 },
  { emoji: "😟", label: "Not Good", rating: 2 },
  { emoji: "😐", label: "Fair", rating: 3 },
  { emoji: "😊", label: "Look Good", rating: 4 },
  { emoji: "😍", label: "Awesome", rating: 5 },
];

const CustomerFeedback = () => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Add navigate hook
  const searchParams = new URLSearchParams(location.search);
  const chatSessionValue = searchParams.get("id");
  const isSubmitted = searchParams.get("submitted"); // Check if feedback is submitted
  const [loading , setLoading] = useState(false)



  const getReviewStatus = async () => {
    setLoading(true)
    try {
      const res = await isAlreadyReviewed({
        chatSessionId: chatSessionValue,
      });
      if (res.data) {
        navigate(`${location.pathname}?id=${chatSessionValue}&submitted=true`, {
          replace: true,
        });
      }
      setLoading(false)
      return res;
    } catch (error) {
      setLoading(false)
      console.log({ error });
    }
  };



  useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  const chatSessionValue = searchParams.get("id");
  const rating= searchParams.get("rating"); 
  const ratingNumber = Number(rating)
  setRating(ratingNumber)
  console.log({rating})
  getReviewStatus() 
  }, []);

  const submitFeedback = async () => {
    if (!rating) {
      setShowWarning(true);
      return;
    }
    try {
      await createReview({
        chatSessionId: chatSessionValue,
        rating,
        comment,
      });
      // Update the URL to include the submitted=true query param
      navigate(`${location.pathname}?id=${chatSessionValue}&submitted=true`, {
        replace: true,});
    } catch (error) {
      console.log({ error });
    }
  };

  const handleEmojiClick = (selectedRating) => {
 const searchParams = new URLSearchParams(location.search);
    searchParams.set("rating", selectedRating); 
    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`, 
    });
    setRating(selectedRating);
    setShowWarning(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <RateYourExperienceStyles>
      {isSubmitted === "true" ? ( // Show thank-you screen if the URL has submitted=true
        <div className="thank-you-card">
          <div className="thank-you-image-container">
            <h3 className="thank-you-title">Thank You for Your Feedback!</h3>
            <img className="thank-you-image" src={feedBackImage} alt="Thank you" />
            <p className="thank-you-description">
              Thank you for sharing your thoughts with us. Your feedback is valuable in helping us enhance our services and deliver a better experience for all.
            </p>
          </div>
        </div>
      ) : (
        <div className="feedback-card">
          <h3 className="title">Rate your Experience</h3>
          <p className="description">
            Your experience matters to us. Please take a moment and let us know how we can make it even better!
          </p>
          <div className="emoji-container">
            {emojis.map((emoji) => (
              <button
                key={emoji.rating}
                className={`emoji-button ${rating === emoji.rating ? "selected" : ""}`}
                onClick={() => handleEmojiClick(emoji.rating)}
              >
                <span className="emoji">{emoji.emoji}</span>
                <label className="emoji-label">{emoji.label}</label>
              </button>
            ))}
          </div>
          <p className="comments">Comments (optional)</p>
          <textarea
            className="comment-input"
            placeholder="Any suggestions for us"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {showWarning && <p className="warning">Please select your review</p>}
          <button className="submit-button" onClick={submitFeedback}>
            SUBMIT
          </button>
        </div>
      )}
    </RateYourExperienceStyles>
  );
};

const RateYourExperienceStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;

  .feedback-card,
  .thank-you-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 450px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: left;
  }

  .title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
  }

  .description {
    color: #666;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .comments {
    color: #666;
    margin-bottom: 10px;
    font-size: medium;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #999;
  }

  .emoji-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 20px;
  }

  .emoji-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s;

    .emoji {
      font-size: 24px;
      margin-bottom: 5px;
      filter: grayscale(100%);
    }

    .emoji-label {
      font-size: 12px;
      color: #666;
    }

    &:hover,
    &.selected {
      transform: scale(1.2);
      .emoji {
        filter: grayscale(0);
      }
    }
  }

  .comment-input {
    width: 100%;
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
    margin-bottom: 20px;
    outline: none;
  }

  .warning {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #8db178;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #7cb342;
    }
  }

  .thank-you-image {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
  }

  .thank-you-description {
    text-align: center;
    color : #52525B;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
  }

  .thank-you-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thank-you-title {
    text-align: center;
    width: 90%;
    align-self: center;
  }
`;

export default CustomerFeedback;
