import React, { useContext, useReducer, createContext, useState } from "react";
import PropTypes from "prop-types";
import { channelReducer } from "./channelReducer";
import {
  CREATE_CHAT_WIDGET_BEGIN,
  CREATE_CHAT_WIDGET_ERROR,
  CREATE_CHAT_WIDGET_SUCCESS,
  GET_CHAT_WIDGET_ERROR,
  GET_CHAT_WIDGET_SUCCESS,
  SET_APPEARANCE,
  SET_CONTACT_FORM,
  SET_IS_QUERY_ADDED,
  SET_ORDER_STATUS,
  SET_TAB_DATA,
  SHOW_ORDER_STATUS,
  UPLOAD_ICON_BEGIN,
  UPLOAD_ICON_ERROR,
  UPLOAD_ICON_SUCCESS,
} from "./channelAction";
import { createChatWidget, getChatWidget } from "../../service/service";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import ChatWithUs from "../../pages/channelConfiguration/chat/ChatWithUs";
import ContactFormScreen from "../../pages/channelConfiguration/chat/ContactFormScreen";
import CheckOrderStatus from "../../pages/channelConfiguration/chat/CheckOrderStatus";
import Appearance from "../../pages/channelConfiguration/chat/Appearance";
import ContactForm from "../../pages/channelConfiguration/chat/ContactForm";
import OrderStatus from "../../pages/channelConfiguration/chat/OrderStatus";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import NewTrackOrder from "../../pages/channelConfiguration/chat/NewTrackOrder";

const initialQueryValue = [
  { value: "Return Order", label: "Return Order" },
  { value: "Delivery", label: "Delivery" },
  { value: "Product Issue", label: "Product Issue" },
  { value: "Other", label: "Other" },
];

const ChannelContext = React.createContext();

const CHAT_INITIAL_CONFIG = {
  shop: "",
  appearanceData: {},
  contactFormValueData: {},
  orderStatusData: {},
  appearance: {
    themeColor: "#729b5a",
    isGradient: "solid",
    gradient: "#729b5a",
    textColor: "#ffffff",
    brandName: "HelpIq",
    brandNamePlaceholder: "HelpIq",
    statusText: "Online - AI Expert",
    statusTextPlaceholder: "Online - AI Expert",
    greetingMessage: "Hey There",
    greetingMessagePlaceholder: "Hey There",
    introMessage: "We are here to help you, Also track your order status here.",
    introMessagePlaceholder:
      "We are here to help you, Also track your order status here.",

    showAvatars: true,
    icon: "",
    isAiBot : false, 
    orderSearch: true,
    orderCancel :true ,
  },
  contactFormValue: {
    contactForm: true,
    destinationEmail: "",
    customerName: true,
    customerEmail: true,
    typeOfQuery: [...initialQueryValue],
    messageInput: true,
    attachment: true,
  },
  orderStatus: {
    title: "Track your order",
    titlePlaceholder: "View order status",
    message:
      "Sure, I'd be happy to help you track your order! 😊 Could you please share your order details, such as the order number, email or phone? Thanks!",
    messagePlaceholder: "Track your order",
  },
  showOrderStatus: false,
  responseMessage: { msg: "", type: "" },
  disableSaveBtn: false,
  scriptUrl: "",
  isDeployed: false, 
  isLoading: true,
  isQueryAdded: false,
  queryList: initialQueryValue,
};

const CHAT_INITIAL_CONFIG_ERROR = {
  appearance: {
    brandName: "",
    statusText: "",
    greetingMessage: "",
    introMessage: "",
  },
  orderStatus: {
    title: "",
    message:
      "",
  },
};



const initialBubble = ["trackOrder", "talkToHuman"];
const trackOrderItems = ["message", "card"];
const ChannelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(channelReducer, CHAT_INITIAL_CONFIG);
  const [queryList, setQueryList] = useState([...initialQueryValue]);
  const [queryValue, setQueryValue] = useState("");
  const [widgetFormattedList, setWidgetFormattedList] = useState({});
  const [widgetFormattedListError, setWidgetFormattedListError] = useState({});
  const [selectedWidgetShop, setSelectedWidgetShop] = useState(null);
  const [talkToHumanClicked, setTalkToHumanClicked] = useState(false);
  const [trackOrderCount, setTrackOrderCount] = useState([...initialBubble]);
  const [disableTrackOrder, setDisableTrackOrder] = useState(false);
  const [showTrackOrder, setShowTrackOrder] = useState(false);
  const [showAppearanceScreen, setShowAppearanceScreen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [currentTabValue, setCurrentTabValue] = useState("Appearance");
  const [showProgress, setShowProgress] = useState(false);

  const initialTabState = [
    {
      tab: "Appearance",
      mobileTab: "Chat order",
      isOpen: true,
      icon: <FontAwesomeIcon icon={faComments} />,
      mobileComponent: <ChatWithUs />,
      component: <Appearance />,
    },
    {
      tab: "Track order",
      mobileTab: "Track order",
      isOpen: false,
      icon: <ZoomInOutlinedIcon />,
      mobileComponent: <NewTrackOrder />,
      component: <OrderStatus />,
    },
  ];

  const [openTab, setOpenTab] = useState([...initialTabState]);
  // const [openTab1, setOpenTab1] = useState([
  //   {
  //     tab: "Appearance",
  //     isOpen: true,
  //     mobileComponent: <AppearanceScreen />,
  //     component: <Appearance />,
  //   },
  //   {
  //     tab: "Contact Form",
  //     isOpen: false,
  //     mobileComponent: <ContactFormScreen />,
  //     component: <ContactForm />,
  //   },
  //   {
  //     tab: "Order Status",
  //     isOpen: false,
  //     mobileComponent: <AppearanceScreen type={"orderStatus"} />,
  //     component: <OrderStatus />,
  //   },
  // ]);
  const setAppearance = (payload) => {
    dispatch({ type: SET_APPEARANCE, payload });
  };

  const setContactForm = (payload) => {
    dispatch({ type: SET_CONTACT_FORM, payload });
  };
  const setOrderStatus = (payload) => {
    dispatch({ type: SET_ORDER_STATUS, payload });
  };

  const setIsQueryAdded = () => {
    dispatch({ type: SET_IS_QUERY_ADDED });
  };

  const setShowOrderStatus = (payload) => {
    dispatch({ type: SHOW_ORDER_STATUS, payload });
  };

  const postChatWidget = async (store, tenantId, tenantStoreId) => {
    const payload = {
      shop: store,
      tenantId,
      tenantStoreId,
      appearance: state.appearance,
      contactForm: state.contactFormValue,
      orderStatus: state.orderStatus,
      query: queryList,
    };
    console.log(payload);
    dispatch({ type: CREATE_CHAT_WIDGET_BEGIN });
    try {
      const result = await createChatWidget(payload);
      dispatch({ type: CREATE_CHAT_WIDGET_SUCCESS, payload: result });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: CREATE_CHAT_WIDGET_ERROR, payload: error.response });
    }
  };
  const uploadIcon = async (file) => {
    if (file) {
      if (!file.type.startsWith("image")) {
        dispatch({ type: UPLOAD_ICON_ERROR, payload: "Please upload a image" });
        return;
      }
      if (file.size > 1000000) {
        dispatch({
          type: UPLOAD_ICON_ERROR,
          payload: "Image size should be less than 1mb",
        });
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);

      dispatch({
        type: UPLOAD_ICON_BEGIN,
      });

      try {
        const location = await uploadToS3(file);
        dispatch({
          type: UPLOAD_ICON_SUCCESS,
          payload: location,
        });
        return location;
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getChannelConfiguration = async (tenantId) => {
    try {
      const result = await getChatWidget(tenantId);
      dispatch({ type: GET_CHAT_WIDGET_SUCCESS, payload: result });
      if (result?.data?.query?.length > 0) {
        setQueryList(result?.data?.query);
      }
    } catch (error) {
      dispatch({ type: GET_CHAT_WIDGET_ERROR });
    }
  };

    const setError = (type, key, value) => {
      setWidgetFormattedListError({
        ...widgetFormattedListError,
        [selectedWidgetShop]: {
          ...widgetFormattedListError[selectedWidgetShop],
          [type]: {
            ...widgetFormattedListError[selectedWidgetShop][type],
            [key]: value,
          },
        },
      })
    }

  const handleTrackOrderClick = () => {
    setDisableTrackOrder(true);
    setShowTrackOrder(true);
    setTrackOrderCount((prev) => {
      let updatedValue = [...prev];
      let talkToHumanIndex = updatedValue.indexOf("talkToHuman");
      updatedValue.splice(talkToHumanIndex, 1);
      return [...updatedValue, ...trackOrderItems];
    });
  };

  const hideDeployButton = async (hide) => {
    setWidgetFormattedList((prev) => {
      let updatedValue = structuredClone(prev);
      updatedValue = {
        ...updatedValue,
        [selectedWidgetShop]: {
          ...updatedValue[selectedWidgetShop],
          isDeployed: hide,
        },
      };
      return updatedValue;
    });
  }

  return (
    <ChannelContext.Provider
      value={{
        ...state,
        setAppearance,
        setContactForm,
        setOrderStatus,
        setShowOrderStatus,
        postChatWidget,
        getChannelConfiguration,
        uploadIcon,
        queryList,
        setQueryList,
        queryValue,
        setQueryValue,
        setIsQueryAdded,
        widgetFormattedList,
        setWidgetFormattedList,
        selectedWidgetShop,
        widgetFormattedListError, 
        setWidgetFormattedListError,
        setSelectedWidgetShop,
        openTab,
        setOpenTab,
        initialTabState,
        talkToHumanClicked,
        setTalkToHumanClicked,
        trackOrderCount,
        setTrackOrderCount,
        disableTrackOrder,
        setDisableTrackOrder,
        showTrackOrder,
        setShowTrackOrder,
        trackOrderItems,
        handleTrackOrderClick,
        showAppearanceScreen,
        showInput,
        setShowInput,
        setShowAppearanceScreen,
        currentTabValue,
        setCurrentTabValue,
        showProgress,
        setShowProgress,
        hideDeployButton,
        setError,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

const useChannelContext = () => {
  return useContext(ChannelContext);
};

export { ChannelProvider, useChannelContext, CHAT_INITIAL_CONFIG, CHAT_INITIAL_CONFIG_ERROR  };
