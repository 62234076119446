import { BrowserRouter, Route, Routes } from "react-router-dom";
import { React, useContext, useEffect, useState } from "react";
import AutoReply from "../components/settings/AutoReply";
import { TenantContext } from "../context/TenantContext";
import SharedLayout from "./SharedLayout";
import Inbox from "../pages/DashboardPage/Inbox";
import Report from "../pages/DashboardPage/Report";
import Settings from "../pages/DashboardPage/Settings";
import EmailSignature from "../components/settings/EmailSignature";
import UserProfile from "../components/settings/UserSetup";
import QuickReply from "../components/settings/QuickReply";
import PricingPlan from "../components/settings/PricingPlan";
import NotFoundPage from "../pages/404";
import Label from "../components/settings/Label";

import CustomDate from "../components/reusable/ReportFeature.js/CustomDate";

import BusinessHours from "../pages/SettingsPage/BusinessHours";
import queryString from "query-string";
import MyProfile from "../pages/DashboardPage/MyProfile";
import ChangePassword from "../components/myProfile/ChangePassword";
import MyProfileSettings from "../components/myProfile/MyProfileSettings";
import FullPageSpinner from "../components/FullPageSpinner";
import { PUBLIC_PATHS } from "../utils/constants";

import reportFeatureCard from "../components/Report/ReportCard";

import FacebookCard from "../components/settings/ChannelConfiguration/FacebookCard";
import { logoutUser, connectShopify, getStoreStatus, apiTraining, buyNewStore } from "../service/service";
import ShopifyCardIntegration from "../components/settings/ShopifyCardIntegration";
import MetaConfiguration from "../components/settings/ChannelConfiguration/MetaConfiguration";
import FacebookIntegration from "../components/settings/ChannelConfiguration/FacebookIntegration";
import Chat from "../pages/channelConfiguration/chat/Chat";
import FacebookConfiguration from "../components/settings/ChannelConfiguration/FacebookConfiguration";
import InstagramCard from "../components/settings/ChannelConfiguration/InstagramCard";
import InstagramIntegration from "../components/settings/ChannelConfiguration/InstagramIntegration";
import WhatsappCard from "../components/settings/ChannelConfiguration/WhatsappSetup";
import WhatsappConfiguration from "../components/settings/ChannelConfiguration/WhatsappConfiguration";
import WhatsappSetup from "../components/settings/ChannelConfiguration/WhatsappSetup";
import WhatsappDialog from "../components/settings/ChannelConfiguration/WhatsappDialog";
import ChannelReport from "../components/Report/ChannelReport/ChannelReports";
import ChannelChat from "../pages/channelConfiguration/chat/ChannelChat";
import InboxMenu from "../components/inboxMenu/InboxMenu";
import ResolutionReport from "../components/Report/ResolutionReport";
import ShopifyConfigPage from "../pages/ShopifyConfig/ShopifyConfigPage";
import Swal from "sweetalert2";
import InstagramConfiguration from "../components/settings/ChannelConfiguration/InstagramConfiguration";
import LabelReport from "../components/Report/ChannelReport/LabelReport";
import UserReport from "../components/Report/ChannelReport/UserReport";

import { regSw, subscribePush } from "../utils/helperFunction";
import FaQTraining from "../components/settings/AiConfiguration/FaQTraining";
import EmailConfiguration from "../components/settings/ChannelConfiguration/EmailConfiguration";
import AiLogs from "../pages/aiConfig/AILogs";
import AITraining from "../pages/aiConfig/AITraining";
import CSATReport from "../pages/CSATReport";

const PrivateRoute = () => {
  const { profile, isTokenLoading, setToken, setTokenLoading, tenantId } = useContext(TenantContext);
  const [isLoading, setLoading] = useState(true);
  const [storeLoading , setStoreLoading] = useState(false)

  const handleLogout = async (parsedParams) => {
    if (profile && profile.token && profile.token.userId) {
      await logoutUser({ userId: profile.token.userId });
    }
    localStorage.removeItem("token");
    localStorage.removeItem("store");
    localStorage.setItem("params", JSON.stringify(parsedParams));
    localStorage.setItem("store", parsedParams.shop);

    window.location = "/login";
  };

  const checkAuth = async () => {
    const { pathname, search } = window.location;

    const parsed = queryString.parse(location.search);
    console.log(
      "Window Location parsed ---",
      pathname,
      parsed,
      profile,
      isLoading,
      isTokenLoading
    );

    // if (isTokenLoading || isLoading) return;
  
    // // Case: Installing from Shopify Directly
    if (pathname === "/" && parsed.hmac && parsed.shop && !parsed.session) {
      const storeStatusRes = await getStoreStatus(parsed.shop);
      console.log(">>> store Status Res", storeStatusRes);
      if (storeStatusRes.success && !storeStatusRes.data) {
        window.location = `${process.env.REACT_APP_BACKEND_APP_URL}/api/auth?shop=${parsed.shop}`;
        return;
      }
    }

    if (pathname === "/" && parsed.hmac && parsed.shop && parsed.session) {
      const storeStatusRes = await getStoreStatus(parsed.shop);

      console.log("Store Status Res ---", storeStatusRes);
      if (storeStatusRes.success && !storeStatusRes.data) {
        const result = await Swal.fire({
          title: "Opps!",
          text: "It appears that you have already removed the app from HelpIQ. To reconnect the app, either reinstall it or reach out to HelpIQ admin at support@helpiq.app.",
          icon: "info",
          showCancelButton: false,
          reverseButtons: false,
          confirmButtonColor: "#8db178",
          cancelButtonColor: "#D9D9D9",
          confirmButtonText: "OK",
        });

        // window.location = `${process.env.REACT_APP_BACKEND_APP_URL}/api/auth?shop=${parsed.shop}`;
        // return;
      }
    }
    if (
      pathname === "/" &&
      parsed.code &&
      parsed.host &&
      parsed.shop &&
      profile?.token
    ) {
      if (isTokenLoading) return;

      setTokenLoading(true);
      const updatedUserLogin = await connectShopify({
        code: parsed.code,
        shop: parsed.shop,
      });

      try {
        setStoreLoading(true)
        const buyShopify = await buyNewStore({ tenantId, shop: parsed.shop });
        const redirectUrl = buyShopify?.paymentUrl;
        console.log("buyShopify ---", buyShopify);
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.log({ redirectUrl });
        }
      } catch (error) {
        setStoreLoading(false)
        console.log('buy shopify error', error);
      }

      console.log("updatedUser Login ---", updatedUserLogin);
      setToken(updatedUserLogin?.token);
      // const currentUrl = new URL(window.location.href);
      // currentUrl.search = "";
      // window.history.replaceState(null, null, currentUrl.toString());

      const url = new URL(window.location.href);
      url.search = '';
      window.history.replaceState({}, '', url);

      return;
    }

    if (
      !isTokenLoading &&
      !isLoading &&
      pathname === "/" &&
      parsed.code &&
      parsed.host &&
      parsed.shop &&
      !profile?.token
    ) {
      console.log(
        "Inside login IF ",
        !isTokenLoading,
        !isLoading,
        pathname === "/",
        parsed.code,
        parsed.host,
        parsed.shop,
        profile
      );
      const currentUrl = new URL(window.location.href);
      window.location.href = `/login${currentUrl.search}`;
      return;
    }

    if (
      PUBLIC_PATHS.includes(pathname) ||
      pathname.startsWith("/verifySubscription")
    ) {
      console.log("1. Is token Loading ", isTokenLoading, profile, isLoading);
      setLoading(false);
      return;
    }

    if (
      !PUBLIC_PATHS.includes(pathname) &&
      (!profile || !profile.token) &&
      !isTokenLoading
    ) {
      console.log("Is token Loading ", isTokenLoading, profile, isLoading);
      window.location.href = "/non-authenticated";
      setLoading(false);
      return;
    } else if (
      profile &&
      profile.token &&
      !profile.token.shop &&
      pathname !== "/settings/connect-shopify" &&
      pathname !== "/settings/pricing"
    ) {
      console.log({shop : profile.token.shop, profile})
      window.location = "/settings/connect-shopify";
      setLoading(false);
    } else if (
      profile &&
      profile.token &&
      !profile.token.pricingPlan &&
      pathname !== "/settings/connect-shopify" &&
      pathname !== "/settings/pricing"
    ) {
      window.location = "/settings/pricing";
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, [isTokenLoading, profile?.token]);

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <>
      {isLoading || isTokenLoading || storeLoading ? (
        <FullPageSpinner />
      ) : (
        <BrowserRouter>
          <Routes>
            {/* {
            !profile?.token ? (
              {nonAuthenticatedRoutes}
            ) : !profile.token.shop ? (
              {shopifyIntegrationRoutes}
            ) : profile?.token?.pricingPlan ? (
              {privateRoutes}
            ) : (
              {pricingPlanRoutes}
            )
          } */}
            <Route path="/" element={<SharedLayout />}>
              <Route element={<InboxMenu />}>
                {/* <Route index element={<Inbox />} /> */}

                <Route path="/" element={<Inbox />}>
                  <Route path="/" element={<Inbox />} />
                  <Route path="chat" element={<Inbox />} />
                  <Route path="email" element={<h1>Email</h1>} />
                  <Route path="whatsapp" element={<h1>Whatsapp</h1>} />
                  <Route path="facebook" element={<h1>Facebook</h1>} />
                  <Route path="instagram" element={<h1>instagram</h1>} />
                </Route>

                <Route path="myInbox" element={<Inbox />}>
                  <Route path="*" element={<Inbox />} />
                </Route>

                <Route path="snoozedMessage" element={<Inbox />}>
                  <Route path="*" element={<Inbox />} />
                </Route>
              </Route>

              <Route path="/404" element={<NotFoundPage />} />

              {/* protected */}
              <Route path="/report" element={<Report />}>
                <Route
                  path="/report/channelReport"
                  element={<ChannelReport />}
                />
                <Route path="/report/labelReport" element={<LabelReport />} />
                <Route path="/report/userReport" element={<UserReport />} />
                <Route
                  path="/report/resolutionReport"
                  element={<ResolutionReport />}
                />
                <Route
                  path="/report/csatReport"
                  element={<CSATReport />}
                />
              </Route>

              <Route path="/myProfile" element={<MyProfile />}>
                <Route index element={<MyProfileSettings />} />
                <Route
                  path="/myProfile/changePassword"
                  element={<ChangePassword />}
                />
              </Route>

              {/* protected */}
              <Route path="/settings" element={<Settings />}>
                <Route
                  path="/settings/businessHours"
                  element={<BusinessHours />}
                />
                <Route
                  path="/settings/resolution"
                  element={<ResolutionReport />}
                />

                <Route
                  path="/settings/whatsappdialog"
                  element={<WhatsappDialog />}
                />
                <Route
                  path="/settings/emailSignature"
                  element={<EmailSignature />}
                />
                 <Route path="/settings/faq" element={<FaQTraining/>}/>
                <Route path="/settings/customdate" element={<CustomDate />} />

                <Route path="channelConfiguration">
                  <Route path="" element={<ChannelChat />} />
                  <Route path="email" element={<EmailConfiguration/>} />
                  <Route path="whatsapp" element={<WhatsappCard />} />
                  <Route path="facebook/instagram" element={<MetaConfiguration />} />
                </Route>
               
                <Route path="aiConfiguration">
                  <Route path="" element={<FaQTraining/>} />
                  <Route path="aiLogs" element={<AiLogs />} />
                  <Route path="aiTraining" element={<AITraining/>} />
                </Route>
                <Route path="userSetup" element={<UserProfile />} />
                <Route
                  path="/settings/connect-shopify"
                  element={<ShopifyConfigPage />}
                />
                <Route
                  path="/settings/whatsappcard"
                  element={<WhatsappCard />}
                />
                <Route
                  path="/settings/whatsappsetup"
                  element={<WhatsappCard />}
                />
                <Route
                  path="/settings/facebookcard"
                  element={<FacebookCard />}
                />
                <Route
                  path="/settings/instagramcard"
                  element={<InstagramCard />}
                />

                <Route
                  path="/settings/facebookconfiguration"
                  element={<FacebookConfiguration />}
                />
                <Route
                  path="/settings/facebookintegration"
                  element={<FacebookIntegration />}
                />

                <Route
                  path="/settings/whatsappconfiguration"
                  element={<WhatsappConfiguration />}
                />
                <Route
                  path="/settings/instagramintegration"
                  element={<InstagramIntegration />}
                />
                <Route
                  path="/settings/instagramConfiguration"
                  element={<InstagramConfiguration />}
                />
                <Route path="/settings/quickReply" element={<QuickReply />} />
                <Route path="/settings/pricing" element={<PricingPlan />} />
                <Route path="/settings/label" element={<Label />} />
                <Route path="/settings/autoReply" element={<AutoReply />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default PrivateRoute;
