/* eslint-disable no-undef */
export const BASE_URL = process.env.REACT_APP_BACKEND_APP_URL;
export const BASE_URL_SERVICE = process.env.REACT_APP_BACKEND_SERVICE_URL;
export const BASE_URL_BACKEND = process.env.REACT_APP_BACKEND_URL;
export const AI_SERVICE_URL =  process.env.REACT_APP_AI_SERVICE_URL ;

export const END_POINTS = {
  registerUser: "api/tenant/register",
  loginUser: "api/tenant/login",
  logoutUser: "api/tenantUserDetails/logoutUser",
  changePassword: "api/tenantUserDetails/changePassword",
  setPassword: "api/tenantUserDetails/setPassword",
  updateUserDetail: "api/tenantUserDetails/updateUserDetail",
  uploadProfileImg: "api/tenantUserDetails/uploadProfileImg",
  getAllUserSetup: "api/tenantUserDetails/allUserSetup",
  getSingleUserDetails: "api/tenantUserDetails/getSingleUserDetails",
  getAllPermission: "api/tenantUserDetails/allPermission",
  inviteUser: "api/tenantUserDetails/inviteUser",
  editUserDetail: "api/tenantUserDetails/editUserDetail",
  deleteUserDetail: "api/tenantUserDetails/deleteUserDetail",
  forgetPassword: "api/tenantUserDetails/forgetpassword",
  getUserPermission: "api/tenantUserDetails/getUserPermission",
  getTeams: "api/tenantUserDetails/getTeams",
  getPricingPlan: "api/pricing/pricingPlan",
  verifyPricingPlanSubscription: "api/pricing/verify-subscription",
  verifyAiToken: "api/pricing/verifyAiToken",
  verifyTicketPurchase: "api/pricing/verify-ticket-subscription",
  subscription: "/apps/tenant/subscription",
  buyNewStore: "/apps/tenant/buyNewStore",
  buyMoreTickets: "/apps/tenant/buyMoreTickets",
  buyAdditionalAITokens: "/apps/tenant/buyAdditionalAITokens",
  verifyOtp: "api/tenantUserDetails/verifyOtp",
  autoReply: "api/autoReply/",
  getAllAutoReply: "api/autoReply/",
  quickReply: "api/quickReply",
  getAllQuickReply: "api/quickReply",
  createFaQTraining: "api/faQTraining",
  getAllFaQTraining: "api/faQTraining",
  addLabel: "api/label",
  getLabel: "api/label",
  deleteLabel: "api/label/deleteLabel",
  deleteFaq: "api/faQTraining/deletefaq",
  updateFaq: "api/faQTraining/updateFaq",
  attachLabelToChatSession: "api/label",
  createChatWidget: "api/chatConfiguration/createChatWidget",
  createBusinessHours: "api/businessHours",
  getBusinessHours: "api/businessHours",
  getChatWidget: "api/chatConfiguration",
  sendMessage: "api/chatMessage",
  getAllMessage: "api/chatMessage",
  setReadMessage: "api/chatMessage/readMessage",
  getAllSession: "api/chatSession",
  getAllResolutionReport: "reports",
  getSingleChatSession: "api/chatSession/singleChatSession",
  getAllTickets: "api/chatSession/getAllTickets",
  //getSingleChatSession: "api/chatSession/singleChatSession",
  updateUnReadMessage: "api/chatSession/unReadMessage",
  mergeTickets: "api/chatSession/mergeTickets",
  removeUnReadMessage: "api/chatSession/removeUnReadMessage",
  updateUserName: "api/chatSession/updateUsername",
  assignTo: "api/chatSession/assignTo",
  updateStatus: "api/chatSession/updateStatus",
  markSessionRead: "api/chatSession/markSessionRead",
  snoozeMessage: "api/chatSession/snooze",
  getShopifyOrders: "apps/shopifyOrders",
  createShopifyOrders: "apps/shopifyOrders",
  setAsCustomer: "api/customerContact",
  setPriority: "api/chatSession/setPriority",
  getAllAutoReplyMessage: "api/autoReply/sendAutoReply",
  subscribe: "api/subscribe",
  fetchWhatsappConfig: "api/tenant/fetchWhatsappConfig",
  fetchFilters: "api/tenant/filters",
  updateFilters: "api/tenant/filters",
  createTicketNote: "api/chatMessage/ticketNote",
  updateWhatsappConfig: "api/tenant/whatsappConfig",
  deleteWhatsappConfig: "api/tenant/deleteWhatsappConfig",
  createPrivateNoteNotification: "api/chatMessage/privateNoteNotification",
  getAllPrivateNoteNotification: "api/chatMessage/privateNoteNotification",
  getAllStore: "api/store",
  checkStoreStatus: "api/store/check-store",
  deleteStore: "api/store/deleteStore",
  getAllChatWidget: "api/chatConfiguration/list",
  connectShopify: "api/tenant/connect-shopify",
  deployWidget: "apps/widget/deploy",
  getAllLabelReports: "label-reports",
  getAllUserReports: "user-reports",
  getAllChannelReports: "channel-reports",
  getSingleShopifyOrders: "apps/shopifyOrders/singleOrderId",
  getSessionByCustomerId: "api/customerContact",
  getSingleShopping: "apps/shopifyOrders/singleShopping",
  sendSocialMediaMessage: "api/chatMessage/sendSocialMediaMessage",
  metaAccountLogin: "api/facebook/auth",
  saveFacebookPage: "api/facebook/saveFacebookPage ",
  getPagePosts: "api/facebook/posts",
  likeComment: "api/facebook/likeComment",
  getAllMessages: "api/chatSession/getAllMessage",
  getInstagramAccountIsConnected:
    "api/instagram/getInstagramAccountIsConnected",
  getAllInstagramPosts: "api/instagram/getAllInstagramPosts",
  replyToInstagramComment: "api/instagram/replyToInstagramComment",
  deleteComment: "api/facebook/deleteComment",
  deleteInstagramComment: "api/Instagram/deleteInstagramComment",
  deleteQuickReply: "api/quickReply/delete",
  deleteFacebookIntegration: "api/facebook/deleteFacebookIntegration",
  getUpdatedSingleChatSession: "api/chatSession/getUpdatedSingleChatSession",
  activateEmail: "api/emailConfiguration/activateEmail",
  sendPinCode: "api/emailConfiguration/sendPinCode",
  verifyPinCode: "api/emailConfiguration/verifyPinCode",
  getAllNotification: "api/subscribe/getAllNotification",
  markNotificationAsSeen: "api/subscribe/markNotificationAsSeen",
  createChatNotification: "api/subscribe/createChatNotification",
  getMessageNotification: "api/chatSession/getMessageNotification",
  uploadToFb: "api/whatsapp/uploadToFb",
  getRemainingTicket: "api/ticketNumber/getRemainingTicket",
  deleteEmailIntegration: "api/emailConfiguration/deleteEmailIntegration",
  createSender: "api/emailConfiguration/createSender",
  deleteEmailMessage: "api/emailConfiguration/deleteEmailMessage",
  createEmailSignature: "api/emailSignature",
  getFaq: "api/ai/faq",
  trainingApi: "api/ai/training",
  getProduct: "api/ai/product",
  getCustomerQueryType: "api/ai/getCustomerQueryType",
  createDomain: "api/emailConfiguration/createDomain",
  verifyDomain: "api/emailConfiguration/authenticateDomain",
  getMLLogs : 'api/ai/getMLLogs',
  resendOtp: "api/tenantUserDetails/resendOtp",
  createReview : "api/satisfaction/createReview",
  getReviewsForStore: "api/satisfaction/getReviewsForStore",
  isAlreadyReviewed : "api/satisfaction/isAlreadyReviewed"
};


export const AI_END_POINTS ={
  suggestReply:"getNextResponse",
  getSummary : 'getSummary',
}
