import styled from "styled-components";
const ReportStyles = styled.div`
  background: #f9f9f9ed;
  /* background-color: red; */
  flex-grow: 1;
  /* flex-shrink: 1; */
  .report-container {
      height: calc(100vh - 115px);
      overflow-y: scroll;
    }
  .resolution-container {

    display: flex;
    height: 100vh;
    flex-direction: column;

    .paper-header,
    .date-container-wrapper,
    .date-container-wrapper .paper-card  {
    }

    
  
    .date-container-wrapper .paper-card {
      height: auto;
    }
    

    .sub-container {
      flex: 1;
      display: flex  !important;
      flex-direction: column;
      height: auto;
      overflow-y: auto;
    }

    .sub-container .paper-card  {
      flex: 1
    }


    .sub-container
    .graph-container {
      display: block;
      overflow-y: hidden;
      min-height: 450px;
      width: calc(100vw - 330px);
      margin-left: 2.5rem;
    }
    .graph-container .chart {
      // min-height: 450px;
    }
  }




  .select-dat {
    //width: 100px;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
  }
  .chart {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .report-table {
    margin-top: 20px;
    margin-left: 2.1rem;
    /* width: 80vw; */
  }

  .paper-card {
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    margin-top: 20px;
    /* width: -webkit-fill-available; */
  }
  .sub-container {
    overflow-y: auto;
    overflow-x: auto;
    // height: 69vh;
    /* max-width: -webkit-fill-available; */
  }
  .graph-container {
    // width: 100%; 

    // height: 80%;
    overflow-y: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
  }
  .date-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    // justify-content:space-around;
    flex-wrap: wrap;
    align-items: center;
  }
  

  .date-box {
    margin-left: 200px;
    margin-top: -29px;
    box-shadow: "0px 1px 7px 0px #00000040";
    width: 100px;
  }
  .date-box1 {
    margin-left: 500px;

    margin-top: -63px;
    box-shadow: "0px 1px 7px 0px #00000040";
  }

  .report-card {
    display: flex;
    gap: 1.5rem;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .dropdown {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .Average-dropdown {
    max-width: 450px;
    flex-grow: 1.5;

  }
  .label-dropdown {
    max-width: 200px;
    flex-grow: 1;
  }
  .channel-dropdown {
    max-width: 200px;
    flex-grow: 1;
  }
  .user-dropdown {
    max-width: 200px;
    flex-grow: 1;
  }


  .date-error {
    margin-top: 15px;
    font-size: 14px;
    color: red;
    margin-left: 10px;
  }
  .time-card {
    display: flex;
    flex-direction: row;
    gap : 4rem;
  }


  @media (max-width : 1450px) {
  .date-container { 
    width: 100%;
  }
  }
  
`;
export default ReportStyles;
