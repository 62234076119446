import styled from "styled-components";
const ChatStyles = styled.div`
  .wrapper {
    margin-bottom: 2rem;
    display: flex;
    overflow-y: auto;
  }

  .chat-setting-container {
    // width: calc(100% - 200px -70px -100px);
    padding-right: 40px;
    padding-left: 40px;
  }

  .store-tab-container {
    margin-right: 40px;
    margin-left: 40px;
  }

  .personalize-wrapper {
    height: 90vh;
    // width: 50vw;
    width: 64%;
  }
  .personalize-heading {
    margin: 2rem 0;
    // margin-left: 2rem;
  }
  .personalize-heading h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .personalize-heading p {
    color: #81848a;
    font-size: 16px;
    font-weight: 400;
  }
  .theme-container {
    display: flex;
    // margin-left: 2rem;
    gap: 3rem;
  }
  .theme-heading {
    display: flex;
    gap: 2rem;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 0.5rem;
    cursor: pointer;
    border-bottom: 0.5px solid #c2c2c2;
    width: fit-content;
  }
  .tab-active {
    color: var(--primary-700);
    border-bottom: 4px solid var(--primary-700);
    margin-bottom: -0.5rem;
  }
  .active {
    color: ${(props) => props.themeColor};
    border-bottom: 3px solid ${(props) => props.themeColor};
    border-radius: 2px;
  }
  .appearance-container {
    margin-top: 10px;
    width: 30vw;
    height : 90vh;
  }
  .appearance-screen {
    /* margin-top: 20px; */

    margin-left: -5rem;
    background-color: red;
    position: absolute;
    right: 440px;
    bottom: 85vh;

  }
  .clipboard {
    cursor: pointer;
    margin-left: -24px;
  }
  .helper-link {
    margin-top:  .5rem;
    color: var(--primary-500);

  }

  .clipboard svg {
    height: 20px !important;
  }
  @media (max-width: 1368px) {
    .appearance-screen {
      margin-left: -5rem;
      // background-color: red;
    }
  }
`;
export default ChatStyles;
