import React from "react";
import styled from "styled-components";
import CustomAvatar from "../reusable/CustomAvatar";
import { getLastMessageTime } from "../../utils/helperFunction";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChannelIcon from "../inboxMessage/ChannelIcon";

const UserHistoryPreviewStyles = styled.div`
  .container {
    height: 110px;
    display: flex;
    padding: 1rem 2rem;
    cursor: pointer;
  }

  .active {
    background: var(--primary-200);
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .details-container {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  .assigned-user {
    color: #009688;
    font-size: 12px;
    font-weight: 500;
    margin: 2px 0px;
  }

  .ticket-status {
    background: #f2f2b3;
    float: right;
    margin-top: 2px;
    padding: 6px 12px;
    border-radius: 15px;
    border: 1px solid #CDDC39;
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    margin-left: auto;
  }

  .ticket-id {
    color: #666;
    font-size: 12px;
    font-weight: 500;
  }

  .time {
    color: #969696;
    font-size: 11px;
    font-weight: 400;
  }

  .msg {
    color: #737373;
    font-size: 15px;
    font-weight: 400;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info-container {
    display: flex;
    clear: both;
    justify-content: space-between;
    width: 450px;
  }
  .right-side {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .merge-btn {
    display: flex;
    align-items: center;
    gap : 1rem;
    margin-top: .5rem;
  }
`;

const UserHistoryPreview = (props) => {
  const {
    latestMessage,
    _id,
    ticketId,
    channelName,
    customerInfo,
    assignUser,
    assignTo,
    status,
    showMergeButton,
    handleMerge,
    disableMerge,
    review,
  } = props;


  const name = customerInfo?.["First Name"]
    ? customerInfo?.["First Name"]
    : "Unknown";
  const {
    setOpenModal,
    setCurrentChat,
    setCurrentSessionData,
    currentSessionData,
  } = useInboxContext();
  const { setPreviewActive } = useTenantContext();
  const handleClick = () => {
    if (showMergeButton) {
      return 
    } 
    setCurrentSessionData(props);
    setPreviewActive({
      isActive: true,
      sessionId: _id,
    });
    setCurrentChat({ name, sessionId: _id, messageType : channelName , isReview : review });
    setOpenModal(false);
  };
  const assignedUserMessage = assignTo ? (
    assignUser && <div className="assigned-user">Assigned to: {assignUser?.name}</div>
  ) : (
    <div className="assigned-user">Not Assigned</div>
  );
  const ticketStatusMessage = status ? (
    <div className="ticket-status">{status}</div>
  ) : null;

  return (
    <UserHistoryPreviewStyles>
      <div
        className={`container ${
          currentSessionData.ticketId === ticketId ? "active" : ""
        }`}
        onClick={() => handleClick(name, _id)}
      >
        <CustomAvatar name={name} />
        <div className="details-container">
          <div className="top-bar">
            <h3 className="name">{name}</h3>
            <span className="message-icon">
              <ChannelIcon channelName={channelName}/>
            </span>
          </div>
          <div className="info-container">
            <div className="left-side">
              <p className="ticket-id">Ticket Id : #{ticketId}</p>
              <div className="msg">{latestMessage?.content}</div>
              <span className="time">
                {getLastMessageTime(latestMessage?.createdAt)}
              </span>
            </div>

            {showMergeButton ? (
              <div className="merge-btn">
                <CheckCircleIcon sx={{ color: "#8db178" }} />
                <PrimaryButton sx={{ boxShadow : 'none', paddingBlock : '3px'}}  disabled={disableMerge} onClick={handleMerge}>
                  Merge
                </PrimaryButton>
              </div>
            ) : (
              <div className="right-side">
                <div>
                  {assignedUserMessage}
                  {ticketStatusMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </UserHistoryPreviewStyles>
  );
};

export default UserHistoryPreview;
