import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 550px; 
  margin: 0 auto;
`;

const ProductsWindow = styled.div`
  width: 660px; // Adjust based on how many products you want to show at once
  overflow: hidden;
`;

const ProductsWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${props => props.translate}px);
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  z-index: 1;
  padding: 0 10px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  flex-shrink: 0;
`;

const ProductImage = styled.img`
  width: 150px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  margin: 5px 0;
  text-align: center;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  color: #888;
  margin: 5px 0;
`;

const AddToCartButton = styled.button`
  background: #8CB077;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
`;

const ProductCarousel = ({ products }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const productsPerPage = 3; // Adjust this value based on how many products you want to show at once

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + productsPerPage >= products.length ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? Math.max(0, products.length - productsPerPage) : prevIndex - 1
    );
  };

  return (
    <CarouselContainer>
      <ArrowButton onClick={prevSlide}>
        <ChevronLeftIcon />
      </ArrowButton>
      <ProductsWindow>
        <ProductsWrapper translate={-currentIndex * 220}> {/* 220px is the width of each product container plus its margin */}
          {products.map((product, index) => (
            <ProductContainer key={index}>
              <ProductImage src={product.image} alt={product.title} />
              <ProductName>{product.title}</ProductName>
              <ProductPrice>Price: ${product.price}</ProductPrice>
              <AddToCartButton>Add to Cart</AddToCartButton>
            </ProductContainer>
          ))}
        </ProductsWrapper>
      </ProductsWindow>
      <ArrowButton onClick={nextSlide}>
        <ChevronRightIcon />
      </ArrowButton>
    </CarouselContainer>
  );
};

export default ProductCarousel;