import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../Logo";
import { reportList } from "../../utils/helperData";
import { useTenantContext } from "../../context/TenantContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const ReportMenuStyles = styled.div`
  /* width: max(15%, 200px); */
  width: 200px;
  border-right: var(--border);
  height: 100vh;
  h3 {
    margin-left: 1rem;
    margin-top: 2rem;
  }
  .dropdown {
    padding: 0 1rem;
  }
  .report-list {
    padding: 1rem;
    position: relative;
    cursor:pointer;
    border-bottom: 1px solid var(--border-clr);
    padding-left: .2rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  /* .report-list::after {
    content: "";
    position: absolute;
    left: 5%;
    bottom: 0;
    width: 90%;
    height: 1px;
    background-color: var(--border-clr);
  } */
  .active {
    margin-top: 0.4rem;
    background-color: var(--primary-100);
  }
`;

const ReportMenu = () => {
  const [selectedReportList, setSelectedReportList] = useState(
    reportList[0].name
  );
  const navigate = useNavigate();



  const { profile } = useTenantContext();

  const handleClick = (name,route) => {
    if (profile && profile.isMenuDisabled) {
      return;
    }
    
    setSelectedReportList(name);
    if (route) {
      navigate(route);
    }
  };
  const location = useLocation();
  const pathName = location.pathname.split("/")[2]

  useEffect(() => {
    if (pathName === 'resolutionReport') {
      setSelectedReportList('Resolution Report')
    }
    if (pathName === 'channelReport') {
      setSelectedReportList('Channels Report')
    }
    if (pathName === 'userReport') {
      setSelectedReportList('Users Report')
    }
    if (pathName === 'labelReport') {
      setSelectedReportList('Labels Report')
    }
    if (pathName === 'csatReport') {
      setSelectedReportList('CSAT Report')
    }
  },[])
  return (
    <ReportMenuStyles>
      <Logo />
      <h3>Report</h3>
      {reportList.map(({ id, name, route }) => {
        return (
          <li
            className={`report-list ${selectedReportList === name ? "active" : null
              } `}
            onClick={() => handleClick(name,route)}
            key={id}
          >
            {name}
          </li>
        );
      })}
    </ReportMenuStyles>
  );
};

export default ReportMenu;
