import React, { useRef, useEffect, useState } from "react";
import { getKey } from "../../utils/helperFunction";
import MessagePreview from "./MessagePreview";
import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const MessagePreviewList = ({
  session,
  highlight,
  setHighlight,
  limit,
  setMessageIndex,
  allLabels
}) => {
  const { previewActive } = useTenantContext();
  const { loadNextSession, chatSessions, setCurrentPage } = useInboxContext();
  const [scrollPosition, setScrollPosition] = useState(0);
  const targetRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      console.log("handleScroll called");
      const scrollContainer = targetRef.current;
      const lastChild = scrollContainer.lastElementChild;
      const lastChildOffset = lastChild?.offsetTop + lastChild?.clientHeight;
      const scrollPosition =
        scrollContainer.scrollTop + scrollContainer.clientHeight;
      // Check if the scroll position is near the last child
      setScrollPosition(scrollPosition);
      // localStorage.setItem("currentScrollPosition", scrollPosition);
      if (scrollPosition > lastChildOffset - 400) {
        // Trigger your function here
        console.log('need to load next')
        localStorage.setItem("scrollPosition", scrollPosition);
        loadNextSession(scrollContainer);
      }
    };

    const scrollContainer = targetRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="message-preview-list" className="messagePreview" ref={targetRef}
    >
      {session.map((message, index) => {
        //   let lastRef = null;
        //   if (session.length > (limit - 1)) {
        //     lastRef = session.length === index + 1 ? lastPostRef : null;
        //   }
        return (
          <div
            key={getKey("session", "name", index)}
            className={`inboxMsgPreview inActive${
              (message._id === highlight && previewActive.isActive === false) ||
              (previewActive.isActive &&
                message._id === previewActive.sessionId)
                ? " active"
                : ""
            }`}
            onClick={() => {
              setHighlight(message._id);
              let indexValue = Math.ceil(index / limit);
              let currentPage = Math.floor(index/6);
              setCurrentPage(currentPage+1)
              let currentClickIndex = index
              console.log({currentPage,index })
              setMessageIndex(index);
            }}
          >
            <MessagePreview index={index} ref={targetRef} position={scrollPosition} {...message}  allLabels={allLabels} />
          </div>
        );
      })}
    </div>
  );
};

export default MessagePreviewList;
