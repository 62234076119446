import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import styled from "styled-components";

ChartJS.register(ArcElement);

const Container = styled.div`
  width: 450px;
  height: 225px;
  position: relative;
  .title-text {
    margin-bottom: .75rem;
  }
`;

const StyledCard = styled.div`
  flex: 1;
  height: 100%;
  align-items: stretch;
  border: 1px solid #c2c2c2;
  box-shadow: 0px 1px 7px 0px #00000040;
  border-radius: 5px;
  padding: 1rem;
  background-color: white;
`;

const HeadSection = styled.div`
  font-weight: 500;
  font-size: 18px;
  display: flex;
  gap: 0.5rem;
`;

const ChartContainer = styled.div`
  position: absolute;
  height: 125%;
  width: 100%;
  margin-left: 3.5rem;
`;

const NeedleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -34px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Needle = styled.div`
  width: 4px;
  height: 30%;
  background-color: #333;
  transform-origin: bottom center;
  transition: transform 1s ease-out;
  position: absolute;
  top: 36%;
  left: 31%;
`;

const CenterText = styled.div`
  position: absolute;
  bottom: 37%;
  left: 32.5%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const data = {
  datasets: [
    {
      data: [10, 10, 10, 10, 10],
      backgroundColor: ["#FF1C25", "#F16D00", "#FABF09", "#75BC46", "#388E3C"],
      display: true,
      borderColor: "#D1D6DC",
    },
  ],
};

const CustomerSatisfactionMeter = ({ rating }) => {
  const [needleRotation, setNeedleRotation] = useState(0);

  useEffect(() => {
    const rotation =
      rating >= 5 ? 70 : rating === 0 ? -90 : (rating / 5) * 180 - 125;
    setNeedleRotation(rotation);
  }, [rating]);

  return (
    <Container>
      <StyledCard>
        <HeadSection>
          <span className="title-icon">
            <ColorLensIcon />
          </span>
          <span className="title-text">Overall Customer Satisfaction </span>
        </HeadSection>
        <ChartContainer>
          <Doughnut
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              rotation: -90,
              circumference: 180,
              cutout: "60%",
              maintainAspectRatio: true,
              responsive: false,
            }}
          />
          <NeedleContainer>
            <Needle style={{ transform: `rotate(${needleRotation}deg)` }} />
          </NeedleContainer>
          <CenterText>
            <div>{rating?.toFixed(1)}</div>
          </CenterText>
          <div
            style={{
              position: "absolute",
              top: "44%",
              left: "9px",
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              fontSize: "24px",
            }}
          >
            <span style={{ position: "absolute", bottom: "-12px", left: "21px" }}>😖</span>
            <span style={{ position: "absolute", bottom: "49px", left: "59px" }}>😟</span>
            <span style={{ position: "absolute", bottom: "69px", left: "28%" }}>😐</span>
            <span style={{ position: "absolute", bottom: "48px", left: "44%" }}>😊</span>
            <span style={{ position: "absolute", top: "-17px", right: "42%" }}>😍</span>
          </div>
        </ChartContainer>
      </StyledCard>
    </Container>
  );
};

export default CustomerSatisfactionMeter;
