import styled from "styled-components";
const UserHistoryStyles = styled.div`
  border-bottom: none;
  .user-history-container {
    height: 500px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.5rem;
  }
  .user-history-open-container {
    width: 85%;
    margin: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .open-container {
    width: 100%;
    margin: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-bar{
    width: 85%;
    margin-left:1rem;
    margin-right:1rem;
  }
  .user-history-search-bar {
    width : 200px;
    margin-right: 30px ;
  }
  .profile {
    margin-top: 1rem;
  }
  .message-card {
    height: 100px;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
    width: 550px;
    border-radius: 1rem;
  }
  .message-preview-container {
    width: 610px;
  }
  .session-card-container {
    height: 500px;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: 5rem; */
    gap: 2rem;
    width: -webkit-fill-available
  }
  .not-found-text {
    margin-top: 3rem;
  }
  .not-found-container {
    width:100%;
    display:  flex;
    flex-direction: column;
    align-items:  center;
    justify-content:  center;
    gap :2rem 
  }
  .not-found-img {
    width: 250px;
    height: 250px;
  }
  .merge-text {
    font-size: 20px;
    font-weight: 500;
    max-width: 90%;
    text-align: center;
  }
`;
export default UserHistoryStyles;
