
import React, { useEffect, useState } from "react";
import UserHistoryStyles from "../../assets/styledComponents/message/UserHistory";
import { Card } from "@mui/material";
import MessagePreview from "../inboxMessage/MessagePreview";
import CustomSearchBox from "../reusable/CustomSearchBox";
import { useTenantContext } from "../../context/TenantContext";
import { getAllTickets, getSessionByCustomerId, mergeTickets } from "../../service/service";
import UserHistoryPreview from "./UserHistoryPreview";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import useDebounce from "../../hooks/useDebounce";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import FullPageSpinner from "../FullPageSpinner";
import mergeTicketBackground from "../../assets/images/mergeTicketBackground.png"

const MergeTickets= (props) => {
  const { customerId, handleClose, setOpenMergeModal } = props;
  const { tenantId } = useTenantContext();
  const {currentSessionData, setCurrentChat, setCurrentSessionData} = useInboxContext()


  const [messageSearch, setMessageSearch] = useState("");
  const [messageDebounceValue] = useDebounce(messageSearch, 500);
  const [sessionsData, setSessionData] = useState();
  const [snackBarError, setSnackBarError] = useState({ msg: "", type: "" });
  const [disableMerge, setDisableMerge] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSearchChange = (e) => {
    setMessageSearch(e.target.value);
    
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setMessageSearch(e.target.value)
    }
  } 


  const fetchTickets = async () => {
    if (currentSessionData.ticketId === messageDebounceValue) return
    if (!messageDebounceValue) return;

    try {
      setLoading(true)
      const result = await getAllTickets({tenantId ,currentSessionId :  currentSessionData._id , searchValue: messageDebounceValue?.trim()  });
      setSessionData(result.data?.[0]);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [messageDebounceValue])

  const handleMerge = async() => {
    if (!sessionsData) return 
    const payload = {
       currentSessionId : currentSessionData._id , mergedSessionId : sessionsData._id, mergedTicketNumber : sessionsData.ticketId, mergedChannelName : sessionsData.channelName, mergeSessionInfo : sessionsData?.customerInfo}
    try {
    const result = await mergeTickets(payload) 
      setSnackBarError({ msg: result.message, type: "success" });
      setDisableMerge(true)
      setCurrentChat({ name :  currentSessionData.name, sessionId: currentSessionData._id, messageType : currentSessionData.channelName });
      setCurrentSessionData((prev) => {
        return{...prev , isMerged : true, mergedSessionId : sessionsData._id, mergedTicketNumber : sessionsData.ticketId, mergedChannelName : sessionsData.channelName } 
      })
    setOpenMergeModal(false) 
    } catch (error) {
      setSnackBarError({ msg: error.response.data, type: "error" });
      console.log({error})
      setDisableMerge(false)
    }
  };


  return (
    <UserHistoryStyles>
      {snackBarError.msg && <CustomSnackbar payload={snackBarError} />}
      <div className="user-history-container">
        <div className="open-container">
          <div className="search-bar">
            <CustomSearchBox
              placeholder={"Search"}
              handleSearch={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        {loading ? (
          <div className="session-card-container">
            {" "}
            <FullPageSpinner />{" "}
          </div>
        ) : (
          <div className="session-card-container">
            {sessionsData ? (
              <div className="message-card" onClick={handleClose}>
                <Card sx={{ borderRadius: ".5rem", marginBottom: "1rem" }}>
                  <UserHistoryPreview
                    handleMerge={handleMerge}
                    disableMerge={disableMerge}
                    showMergeButton={true}
                    {...sessionsData}
                  />
                </Card>
              </div>
            ) : messageDebounceValue.trim() !== "" ? (
              <h3 className="not-found-text">
                No Ticket Found with the Id {messageDebounceValue}
              </h3>
            ) : (
              <div className="not-found-container">
                <img
                  src={mergeTicketBackground}
                  alt="not found"
                  className="not-found-img"
                />
                <p className="merge-text">
                  You haven’t search anything yet, search tickets for merging
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </UserHistoryStyles>
  );
};

export default MergeTickets;
