import React, { useRef, useState } from "react";
import AppearanceStyles from "../../../assets/styledComponents/channelConfiguration/chat/Appearance";
import {
  Avatar,
} from "@mui/material";
import ColorPicker from "../../../components/reusable/ColorPicker";
import CustomTextField from "../../../components/reusable/CustomTextField";
import CustomSwitch from "../../../components/CustomSwitch";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
import { FileUpload } from "@mui/icons-material";
import hiIcon from "../../../assets/images/hiIcon.png";
import CustomProgressBar from "../../../components/reusable/CustomProgressBar";

const Appearance = () => {
  const {
    // setAppearance,
    // appearance,
    uploadIcon,
    widgetFormattedList,
    widgetFormattedListError,
    setWidgetFormattedList,
    selectedWidgetShop,
    showProgress, 
    setShowProgress,
    setError,
    hideDeployButton,
  } = useChannelContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  const selectedWidgetDataError = widgetFormattedListError[selectedWidgetShop];

  if (!selectedWidgetData) return;
  const {
    themeColor,
    gradient,
    isGradient,
    textColor,
    greetingMessage,
    greetingMessagePlaceholder,
    introMessage,
    introMessagePlaceholder,
    brandName,
    brandNamePlaceholder,
    statusText,
    statusTextPlaceholder,
    showAvatars,
    icon,
    isAiBot,
    orderSearch,
    orderCancel ,
  } = selectedWidgetData.appearance;

  const {
    brandName : brandNameError,
    statusText : statusTextError,
    greetingMessage : greetingMessageError,
    introMessage : introMessageError,
  } = selectedWidgetDataError?.appearance || {}


  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const setAppearanceValue = (key, value) => {
    setWidgetFormattedList({
      ...widgetFormattedList,
      [selectedWidgetShop]: {
        ...widgetFormattedList[selectedWidgetShop],
        appearance: {
          ...widgetFormattedList[selectedWidgetShop].appearance,
          [key]: value,
        },
      },
    });
  };

  const handleColorChange = (color, colorKey) => {
    console.log(color, colorKey);
    setAppearanceValue(colorKey, color.hex);
    // setAppearance({ [colorKey]: color.hex });
  };

  const handleRadioChange = (e) => {
    console.log(isGradient, e.target.value);
    setAppearanceValue("isGradient", e.target.value);
  };

  const handleFileChange = async (event) => {
    setProgress(0)
    setShowProgress(true)
    try {
      const file = event.target.files[0];
      const location = await uploadIcon(file);
      setProgress(100)
      setAppearanceValue("icon", location);
    } catch (error) {
      console.log(error)
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const onValueChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (type === 'checkbox') {
      setAppearanceValue(name, checked);

      return;
    }

    setAppearanceValue(name, value);

    const currentShopAppearance = widgetFormattedList[selectedWidgetShop].appearance;
    if (currentShopAppearance[name]) {
      setError("appearance", name, "")
      return
    }
    // setAppearance({ [name]: value });
  };
  return (
    <AppearanceStyles themeColor={themeColor}>
      {/* <div className="radio-btn">
        <FormControl>
          <h3 className="appearance-heading">Select Theme Color</h3>
          <RadioGroup row value={isGradient} onChange={handleRadioChange}>
            <FormControlLabel
              sx={{ color: "#666666", padding: 0 }}
              value="solid"
              label="Solid"
              control={<Radio sx={{ paddingLeft: 1 }} />}
            />
            <FormControlLabel
              sx={{ marginLeft: "2rem", color: "#666666" }}
              value="gradient"
              control={<Radio sx={{ paddingLeft: 1 }} />}
              label="Gradient"
            />
          </RadioGroup>
        </FormControl>
      </div> */}

      <h3 className="appearance-heading">Select Theme Color</h3>
      <ColorPicker
        name="themeColor"
        value={themeColor}
        onValueChange={onValueChange}
        handleColorChange={(color) => handleColorChange(color, "themeColor")}
        color={themeColor}
      />
      {/* {isGradient === "gradient" && (
        <div className="gradient-picker">
          <ColorPicker
            name="gradient"
            value={gradient}
            onValueChange={onValueChange}
            handleColorChange={(color) => handleColorChange(color, "gradient")}
            color={gradient}
          />
        </div>
      )} */}
      {/* <h3 className="appearance-heading">Select Text Color</h3>
      <ColorPicker
        name="textColor"
        value={textColor}
        onValueChange={onValueChange}
        handleColorChange={(color) => handleColorChange(color, "textColor")}
        color={textColor}
        isTextColor={true}
        penColor={textColor}
      /> */}
      <h3 className="appearance-heading">Select Icon</h3>
      <div className="icon-container">
        <Avatar
          variant="square"
          src={icon ? icon : hiIcon}
          sx={{ width: 50, height: 50 }}
        />
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <button type="" onClick={handleButtonClick}>
          <FileUpload />
        </button>
        {showProgress && (
          <div className="progress-bar">
            <CustomProgressBar progress={progress} setProgress={setProgress} />
          </div>
        )}
      </div>
      <h3 className="appearance-heading">Brand Name</h3>
      <CustomTextField
        name={"brandName"}
        placeholder={brandNamePlaceholder}
        handleChange={onValueChange}
        helperText={brandNameError}
        error={!!brandNameError}
        value={brandName}
      />
      <h3 className="appearance-heading">Status Text</h3>
      <CustomTextField
        name={"statusText"}
        placeholder={statusTextPlaceholder}
        handleChange={onValueChange}
        value={statusText}
        helperText={statusTextError}
        error={!!statusTextError}
      />
      <h3 className="appearance-heading">Greeting Message</h3>
      <CustomTextField
        name={"greetingMessage"}
        placeholder={greetingMessagePlaceholder}
        handleChange={onValueChange}
        value={greetingMessage}
        helperText={greetingMessageError}
        error={!!greetingMessageError}
      />
      <h3 className="appearance-heading">Intro Message</h3>
      <CustomTextField
        name={"introMessage"}
        value={introMessage}
        handleChange={onValueChange}
        placeholder={introMessagePlaceholder}
        row={2}
        helperText={introMessageError}
        error={!!introMessageError}
      />
      <div style={{ paddingBottom: 40 }}>
        <div className="show-avatars">
          <h3>Show Avatars</h3>
          <div className="switch">
            <CustomSwitch
              name={"showAvatars"}
              checked={showAvatars}
              handleToggleSwitch={onValueChange}
            />
          </div>
        </div>
        <div className="show-avatars">
          <h3>AI Bot</h3>
          <div className="switch">
            <CustomSwitch
              name={"isAiBot"}
              checked={isAiBot}
              handleToggleSwitch={onValueChange}
            />
          </div>
        </div>

        <div className="show-avatars">
          <h3>Order Search</h3>
          <div className="switch">
            <CustomSwitch
              name={"orderSearch"}
              checked={orderSearch}
              handleToggleSwitch={onValueChange}
            />
          </div>
        </div>
        <div className="show-avatars">
          <h3>Order Cancel</h3>
          <div className="switch">
            <CustomSwitch
              name={"orderCancel"}
              checked={orderCancel}
              handleToggleSwitch={onValueChange}
            />
          </div>
        </div>
      </div>

      <br />
    </AppearanceStyles>
  );
};

export default Appearance;
