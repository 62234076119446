import React from "react";
import styled from "styled-components";

// Define the emojis and their corresponding labels
const emojis = [
  { emoji: "😖", label: "Worst", rating : 1 },
  { emoji: "😟", label: "Not Good", rating : 2 },
  { emoji: "😐", label: "Fair", rating : 3 },
  { emoji: "😊", label: "Look Good" , rating : 4},
  { emoji: "😍", label: "Awesome", rating : 5 },
];

const RateYourExperience = ({ rating  }) => {
  return (
    <RateYourExperienceStyles>
      <div className="container">
        <h3>Rate your experience</h3>
        <div className="emoji-container">
          {emojis.map(({ emoji, label, rating : originalRating }, index) => (
            <div key={index} className="emoji-item">
              {/* Conditionally apply color to the selected emoji */}
              <p
                className="emoji"
                style={{
                  filter: rating === originalRating  ? "grayscale(0)" : "grayscale(1)",
                }}
              >
                {emoji}
              </p>
              <p className={rating=== originalRating? "selected-label" : "label"}>{label}</p>
            </div>
          ))}
        </div>
      </div>
    </RateYourExperienceStyles>
  );
};

export default RateYourExperience;

const RateYourExperienceStyles = styled.div`
  text-align: left;
  padding-inline: 1rem;
  h3 {
    margin-bottom: 1.5rem;
  }

  .emoji-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .label  {
    font-size  : 12px;
    color: gray;
    font-weight: 500;
  }
  .selected-label  {
    font-size  : 12px;
    color: black;
    font-weight: 500;
  }

  .emoji-item {
    text-align: center;

    .emoji {
      font-size: 40px;
    }
  }
`;
