import React, { useEffect, useState } from 'react'
import CustomSearchBox from '../reusable/CustomSearchBox'
import CustomInputDropDown from '../CustomInputDropDown'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useTenantContext } from '../../context/TenantContext'
import { getAllUserSetup } from '../../service/service'
import { Button } from '@mui/material'
import LogoSpinner from '../reusable/Loader/LogoSpinner'

const CSATInput = ({channelList, channelValue, handleInputChange, selectedUser,setTicketId , ticketId, fromDate, setFromDate, toDate, setToDate, handleButtonClick, isLoading }) => {

  const { tenantId } = useTenantContext();
  const [userDetails, setUserDetails] = useState([]);
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      if (result.data) {
        let userValue = result.data?.map((item) => {
          return { name: item.name, value: item._id };
        });
        setUserDetails([{ name: "All", value: "" }, ...userValue]);
      } else {
        console.log("error");
      }
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setTicketId(e.target.value)
      console.log("Enter key pressed" , e.target.value);
    }
  }

  const today = moment();
  const shouldDisableToDate = (date) => {
    return (
      fromDate
      && (date.isBefore(fromDate) || date.isAfter(today))
    );
  };

  const handleSelectedFromDate = (date) => {
    setFromDate(date);
    if (toDate&& date.isAfter(toDate)) {
      setToDate(null);
    }
  };

  return (
      <div className="input-container paper-card">
        <div className="date-container">
        <div className="input-box">
          <CustomInputDropDown
            icon={<PeopleAltIcon />}
            label={'User'}
            id ={'user'}
            selectList={userDetails}
            handleChange={handleInputChange}
            value={selectedUser}
          />
        </div>
        <div className="input-box">
          <CustomInputDropDown
            icon={<PeopleAltIcon />}
            label={'Channels'}
            id ={'channel'}
            selectList={channelList}
            handleChange={handleInputChange}
            value={channelValue}

          />
          </div>
        <div className="date-bo">
          <LocalizationProvider  dateAdapter={AdapterMoment}>
            <DemoContainer   components={["DatePicker"]}>
              <DatePicker
                // sx={{width: '100px',
                //  }}
                label="From"
                shouldDisableDate={(date) =>
                  date.isAfter(today) ||
                  date.isBefore(moment().subtract(6, "months"))
                }
                value={fromDate}
                onChange={handleSelectedFromDate}
                renderInput={(startProps) => (
                  <input
                    {...startProps.inputProps}
                    placeholder="DD/MM/YYYY"
                    readOnly
                  />
                )}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div className="date-bo">
          <LocalizationProvider  dateAdapter={AdapterMoment}>
            <DemoContainer   components={["DatePicker"]}>
              <DatePicker
                // sx={{width: '100px',
                //  }}
                label="To"
                shouldDisableDate={shouldDisableToDate}
                value={toDate}
                onChange={(value) => setToDate(value)}
                renderInput={(startProps) => (
                  <input
                    {...startProps.inputProps}
                    placeholder="DD/MM/YYYY"
                    readOnly
                  />
                )}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="display-button">
          {isLoading ? (
            <Button
              variant="contained"
              style={{ color: "white", padding: ".7rem 3rem" }}
            >
              <LogoSpinner />
            </Button>
          ) : (
            <Button
              onClick={handleButtonClick}
              variant="contained"
              fullWidth
              style={{ color: "white", padding: ".7rem 3rem" }}
            >
              Display
            </Button>
          )}
        </div>
        </div>
      </div>
  )
}

export default CSATInput