import React, { useEffect, useMemo, useState } from "react";
import CSATReportStyles from "../assets/styledComponents/CSATReport";
import TimelineIcon from "@mui/icons-material/Timeline";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import moment from "moment";
import HeaderBar from "../components/reusable/HeaderBar/HeaderBar";
import CustomerSatisfactionMeter from "../components/message/CustomerSatisfactionMeter";
import ReportCard from "../components/Report/ReportCard";
import CSATInput from "../components/CSATReport/CSATInput";
import CustomInputDropDown from "../components/CustomInputDropDown";
import { useTenantContext } from "../context/TenantContext";
import { getReviewsForStore } from "../service/service";
import ChannelIcon from "../components/inboxMessage/ChannelIcon";
import Ratings from "../components/CSATReport/Ratings";
import CSATSkeletonLoadingDashboard from "../components/CSATReport/CSATReportLoading";
import Table from "../components/reusable/table/Table";

const list = [
  { value: "All", name: "All", id: 1 },
  { value: "chat", name: "Live Chat", id: 2 },
  { value: "mail", name: "Email", id: 3 },
  { value: "instagram", name: "Instagram", id: 4 },
  { value: "whatsapp", name: "Whatsapp", id: 5 },
  { value: "facebook", name: "Facebook", id: 6 },
];

const CSATReport = () => {
  const { profile, tenantStores, selectedShop, tenantId } = useTenantContext();

  const [tableLoading, setTableLoading] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [reviewTableDetails, setReviewTableDetails] = useState([]);
  const [storeName, setStoreName] = useState(
    selectedShop || profile?.token.shop
  );
  const [overallRating, setOverallRating] = useState(0);
  const [channelValue, setChannelValue] = useState("All");
  const [selectedUser, setSelectedUser] = useState("All");
  const [dashboardData, setDashboardData] = useState({
    overallRating: 0,
    totalSurveySent: 0,
    totalSurveyReceived: 0,
    averageResolutionTime: 0,
    totalResolutionTickets : 0,
    resolutionTicket: 0,
  });
  const [ticketId, setTicketId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const today = moment();
  const oneWeekAgo = moment().subtract(7, 'days');  
  const [fromDate, setFromDate] = useState(oneWeekAgo);
  const [toDate, setToDate] = useState(today);
  const [limit, setLimit] = useState(10);

  const handleDropDownChange = (e) => {
    setStoreName(e.target.value);
    setCurrentPage(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "channel") setChannelValue(value);
    if (name === "user") setSelectedUser(value);
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const getReviews = async (currPage) => {
    if (!currPage) {
      setTableLoading(true);
    } else {
      setTableDataLoading(true);
    }
    try {
      let payload = {
        tenantId,
        storeName,
        channel: channelValue,
        user: selectedUser,
        page: currentPage,
        limit,
        fromDate : fromDate  ? moment(fromDate).toISOString() : '',
        toDate : toDate ? moment(toDate).toISOString() : ''
      }
      const result = await getReviewsForStore(payload);

      const {
        reviews,
        totalSurveySent,
        totalSurveyReceived,
        overallRating,
        pagination,
        averageResolutionTime,
        totalResolutionTickets, 
      } = result?.data || {};

      setOverallRating(overallRating);
      setTotalPages(pagination.totalPages);
      setTotalDocs(pagination.totalDocs);

      setDashboardData({
        overallRating,
        totalSurveySent,
        totalSurveyReceived,
        averageResolutionTime,
        resolutionTicket: 0,
        totalResolutionTickets
      });

      if (reviews?.length > 0) {
        const rows = reviews.map((review, index) => ({
          id: index + 1,
          ticketId: review.ticketId,
          userName: review.userName || "Unknown",
          date: moment(review.createdAt).format("MM/DD/YYYY"),
          assignees: review.assignees || "Unknown",
          channel: (
            <div
              style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <ChannelIcon channelName={review.channel} />
              <p>{review.channel}</p>
            </div>
          ),
          rating: <Ratings rating={review.rating} />,
          feedBack: review.feedBack || "No Feedback Given",
        }));

        setReviewTableDetails(rows);
      } else {
        setReviewTableDetails([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTableLoading(false);
      setTableDataLoading(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, [storeName]);

  useEffect(() => {
    getReviews(true);
  }, [currentPage, limit]);

  const columns = useMemo(
    () => [
      { field: "ticketId", headerName: "Ticket ID" },
      { field: "userName", headerName: "User Name" },
      { field: "date", headerName: "Date" },
      { field: "assignees", headerName: "Assignees" },
      {
        field: "channel",
        headerName: "Channel",
        renderCell: (params) => params.value,
      },
      {
        field: "rating",
        headerName: "Rating",
        renderCell: (params) => params.value,
      },
      { field: "feedBack", headerName: "Feedback" },
    ],
    []
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleButtonClick = () => {
    getReviews();
  }

  return (
    <CSATReportStyles>
      <HeaderBar
        title="CSAT Report"
        hidePrimaryButton
        hideSecondaryButton
        showDescription={false}
        paperHeader={true}
        showSelectBox
        selectBtn={
          <CustomInputDropDown
            label={"Select Store"}
            name={"shop"}
            value={storeName}
            selectList={tenantStores}
            required
            onChange={handleDropDownChange}
            width={"250px"}
            height={"35px"}
            formControlSx={{
              backgroundColor: "#8DB178",
              outline: "none",
              border: "none",
            }}
            inputLabelSx={{
              marginTop: "-.4rem",
              marginLeft: "-.4rem",
              fontSize: ".7rem",
              color: "white",
              border: "none",
              fontWeight: "500",
            }}
          />
        }
      />

      <div className="container">
        <CSATInput
          channelList={list}
          channelValue={channelValue}
          setTicketId={setTicketId}
          ticketId={ticketId}
          fromDate={fromDate}
          toDate={toDate}
          setToDate={setToDate}
          handleInputChange={handleInputChange}
          selectedUser={selectedUser}
          setFromDate={setFromDate}
          handleButtonClick={handleButtonClick}
          loading={tableLoading}
        />

        {tableLoading ? (
          <CSATSkeletonLoadingDashboard />
        ) : (
          <div>
            <div className="top-container">
              <CustomerSatisfactionMeter rating={overallRating} />
              <div className="cards-container">
                <div className="horizontal-cards">
                  <ReportCard
                    name="Total Survey sent"
                    icon={<TimelineIcon />}
                    time1={dashboardData.totalSurveySent}
                  />
                  <ReportCard
                    name="Total Response Received"
                    icon={<SentimentSatisfiedAltIcon />}
                    time1={dashboardData.totalSurveyReceived}
                  />
                </div>
                <div className="horizontal-cards">
                  <ReportCard
                    name="Average resolution time"
                    icon={<TimelapseOutlinedIcon />}
                    time1={dashboardData.averageResolutionTime || 0}
                    unit="mins"
                  />
                  <ReportCard
                    name="Total resolution tickets"
                    icon={<TimerOutlinedIcon />}
                    time1={dashboardData.totalResolutionTickets}
                  />
                </div>
              </div>
            </div>
            <div className="table-container report-table paper-card ">
              <Table
                headerData={columns}
                rowData={reviewTableDetails}
                currentPage={currentPage}
                setPage={handlePageChange}
                loading={tableDataLoading}
                totalPages={totalPages}
                tableLoading={tableLoading}
                emptyTableMessage="No data found"
                setLimit={setLimit}
                limit={limit}
                totalDocs={totalDocs}
              />
            </div>
          </div>
        )}
      </div>
    </CSATReportStyles>
  );
};

export default CSATReport;
